import React from 'react'
import './Whyuscards.css'
import img1 from '../blockchain/Images/Expertise.png'
import img2 from '../blockchain/Images/Innovation.png'
import img3 from '../blockchain/Images/security.png'
import img4 from '../blockchain/Images/solutions.png'


const Whayuscards = () => {
  return (
    <div className=' rp flex flex-wrap flex-col sm:flex-row  gap-12 justify-center items-center'>
        <div className=' mb-16'>
        <div className="  card">
<div className="card-img">
    <img src={img1} alt="" className=" " />
    
</div>
  <div className="card-info">
    <p className="text-title">Expertise</p>
    <p className="text-body">Our team comprises blockchain enthusiasts with a deep understanding of this technology's inner workings.</p>
    </div>
</div>
 </div>

 <div className=' mb-16'>
        <div className="  card ">
<div className="card-img">
<img src={img2} alt="" className=" " />
</div>
  <div className="card-info">
    <p className="text-title">Innovation</p>
    <p className="text-body">We stay at the forefront of blockchain advancements, ensuring you benefit from the latest and greatest.</p>
    </div>
</div>
 </div>

 <div className=' mb-16'>
        <div className="  card">
<div className="card-img">
<img src={img3} alt="" className=" " />
</div>
  <div className="card-info">
    <p className="text-title">Security</p>
    <p className="text-body">Security is paramount in the blockchain world. We follow the best practices to keep your data and assets safe.</p>
    </div>
</div>
 </div>

 <div className=' mb-16'>
        <div className="  card">
<div className="card-img">
<img src={img4} alt="" className=" " />
</div>
  <div className="card-info">
    <p className="text-title">Tailored Solutions</p>
    <p className="text-body">We do not do one-size-fits-all. Every blockchain solution we create is designed to meet your specific needs.</p>
    </div>
</div>
 </div>


    </div>
  )
}

export default Whayuscards
