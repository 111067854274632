import React from 'react';
import { Link } from 'react-router-dom';
import technical_job from '../../../assets-insightsnest/user.png';
import atomic from '../../../assets-insightsnest/atomic-energy.png';
import family from '../../../assets-insightsnest/family.png';
import life from '../../../assets-insightsnest/gavel.png';

export const servicesData = [
    {
        id: '1',
        icon: technical_job,
        services: 'Technical Jobs',
        title: 'Technical Jobs',
        subtitle: 'Career Opportunities for Engineers (Technical Jobs)',
        message: 'Are you an aspiring engineer eager to carve out a successful career path in the dynamic world of technology? Join our exclusive webinar led by a distinguished expert in the field to unlock the doors to a plethora of exciting opportunities in government departments.'
    },
    {
        id: '2',
        icon: atomic,
        services: 'Department of Atomic Energy',
        title: 'Department of Atomic Energy',
        subtitle: 'Career Opportunities for Engineers in the Department of Atomic Energy',
        message: 'Embark on a fascinating journey into the realm of nuclear science and discover the vast array of career opportunities awaiting engineers within the Department of Atomic Energy. Led by a seasoned professional with a wealth of experience in this specialized field, this webinar is your gateway to an exciting future.'
    },
    {
        id: '3',
        icon: family,
        services: 'Family Counselling',
        title: 'Family Counselling',
        subtitle: 'Swimming with Sharks for a Successful Life',
        message: "Marriage is a beautiful journey filled with joys, challenges, and everything in between. However, navigating the complexities of married life requires more than just love and commitment—it demands effective communication, mutual respect, and resilience in the face of adversity. Join these transformative sessions led by a seasoned expert to embark on a journey of self-discovery and growth."
    },
    {
        id: '4',
        icon: life,
        services: 'Life Buoy',
        title: 'Life Buoy',
        subtitle: 'Life Buoy in Family Matters',
        message: "In every family, conflicts and disagreements are inevitable. However, it's how we address and resolve these challenges that truly defines the strength of our relationships. If you find yourself struggling to navigate family disputes or maintain harmony within your household, a seasoned expert is here to offer a guiding hand."
    },
];

function ServicesCard({ icon, title, subtitle, message, id, service }) {
    return (
        <div className='flex flex-col w-full xl:w-[580px]  bg-slate-100 p-7 rounded-xl hover:drop-shadow-2xl' data-aos="zoom-out" >
            <div className="flex items-center mb-4">
                <img src={icon} alt="icons" className='w-12' data-aos="zoom-in" data-aos-delay="200" />
                <p className='text-2xl items-center font-semibold flex-1 ml-2' data-aos="zoom-in" data-aos-delay="200" >{title}</p>
            </div>
            <p className='text-xl text-[#0094eb] ml-7 font-bold flex-1'data-aos="zoom-in-right" data-aos-delay="250" >{subtitle}</p>
            <p className='relative text-base ml-7 text-justify pr-5'data-aos="zoom-in-left" data-aos-delay="280" >
                {message}
            </p>
            <footer className='flex items-center justify-end mt-4'>
                <Link to={`/insightsnest/service/${id}`} className='text-[#0094eb] font-bold text-sm hover:underline'>
                    <button className="relative group cursor-pointer text-sky-50  overflow-hidden h-9 w-32 rounded-full  bg-blue-800 p-2 flex justify-center items-center font-bold" data-aos="zoom-in-left" data-aos-delay="100" >
                        <div className="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-40 h-40 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-blue-900"></div>
                        <div className="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-32 h-32 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-blue-800"></div>
                        <div className="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-24 h-24 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-blue-700"></div>
                        <div className="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-14 h-14 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-blue-600"></div>
                        <p className="z-10">Join now</p>
                    </button>
                </Link>
            </footer>
        </div>
    );
}

const Services = () => {
    return (
        <div className='sm:mb-32 mb-10 rp' data-aos="fade-up"  >
            <h1 className='sm:text-4xl text-2xl font-semibold pb-8 text-center text-blue-500 uppercase mb-10'>Specialized Help for You</h1>
            <div className="px-5 grid gap-20 lg:grid-cols-2 md:grid-cols-1 max-w-screen-xl w-full mx-auto py-4">
                {servicesData.map(service => (
                    <ServicesCard {...service} key={service.id} />
                ))}
            </div>
        </div>
    );
};

export default Services;
