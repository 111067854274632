import HTML from '../../assets/home/slider/HTML.png'
import CSS from '../../assets/home/slider/CSS.png'
import Bootstrap from '../../assets/home/slider/Bootstrap.png'
import Tailwind from '../../assets/home/slider/TailwindCSS.png'
import JavaScript from '../../assets/home/slider/JavaScript.png'
import Reactjs from '../../assets/home/slider/React.png'
import Next from '../../assets/home/slider/Nextjs.png'
import Flutter from '../../assets/home/slider/Flutter.png'
import Node from '../../assets/home/slider/Nodejs.png'
import Express from '../../assets/home/slider/Expressjs.png'
import MySQL from '../../assets/home/slider/MySQL.png'
import MongoDB from '../../assets/home/slider/MongoDB.png'
import Django from '../../assets/home/slider/Django.png'
import Java from '../../assets/home/slider/java-original.svg'
import Python from '../../assets/home/slider/python-original.svg'
import Three from '../../assets/home/slider/Threejs.png'
import Blockchain from '../../assets/home/slider/Blockchain.png'
import Figma from '../../assets/home/slider/Figma.png'
import Wordpress from '../../assets/home/slider/wordpress-plain.svg'
import Analytics from '../../assets/home/slider/Googleanalytics.png'
import SEO from '../../assets/home/slider/SEO.png'
import Coreldraw from '../../assets/home/slider/Coreldraw.png'
import Jira from '../../assets/home/slider/Jira.png'
import Excel from '../../assets/home/slider/Excel.png'

import Slider from 'react-infinite-logo-slider'

function LogoSlider() {
    const isScreenSm = window.innerWidth <= 640; // Define your own breakpoint
  
    return (
      <div className={isScreenSm ? 'sm:mb-32 mb-16' : 'mb-20'}>
        <Slider
          width= '200px'
          duration={70}
          pauseOnHover={true}
          blurBorders={false}
          blurBoderColor={'#fff'}
        >

        <Slider.Slide>

            <img className='sm:w-16 w-12 ' src={HTML} alt="HTML logo" />

        </Slider.Slide>


        <Slider.Slide>

            <img className='sm:w-16 w-12 ' src={CSS} alt="CSS logo" />

        </Slider.Slide>


        <Slider.Slide>

            <img className='sm:w-16 w-12 ' src={JavaScript} alt="JavaScript logo" />
      
        </Slider.Slide>


        <Slider.Slide>
      
            <img className='sm:w-16 w-12 ' src={Bootstrap} alt="Bootstrap logo" />
       
        </Slider.Slide>
       
       
        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Tailwind} alt="Tailwind logo" />
     
        </Slider.Slide>
     
     
        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Reactjs} alt="Tailwind logo" />
     
        </Slider.Slide>
     
     
        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Next} alt="Next logo" />
     
        </Slider.Slide>
     
     
        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Flutter} alt="Flutter logo" />
     
        </Slider.Slide>
     
     
        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Node} alt="Node logo" />
     
        </Slider.Slide>
     
     
        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Express} alt="Express logo" />
     
        </Slider.Slide>
     
     
        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={MySQL} alt="MySQL logo" />
     
        </Slider.Slide>
       

        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={MongoDB} alt="MongoDB logo" />
     
        </Slider.Slide>
       

        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Django} alt="Django logo" />
     
        </Slider.Slide>
       

        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Java} alt="Java logo" />
     
        </Slider.Slide>
       

        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Python} alt="Python logo" />
     
        </Slider.Slide>

       
        <Slider.Slide>
     
            <img className='w-16 mix-blend-ssm:cree w-12n' src={Three} alt="Python logo" />
     
        </Slider.Slide>
       

        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Blockchain} alt="Blockchain logo" />
     
        </Slider.Slide>
       

        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Figma} alt="Figma logo" />
     
        </Slider.Slide>
       

        <Slider.Slide>
     
            <img className='sm:w-28 w-24  ' src={Wordpress} alt="Wordpress logo" />
     
        </Slider.Slide>
       

        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Analytics} alt="Analytics logo" />
     
        </Slider.Slide>
       

        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={SEO} alt="SEO logo" />
     
        </Slider.Slide>
       

        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Coreldraw} alt="Coreldraw logo" />
     
        </Slider.Slide>
       

        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Jira} alt="Jira logo" />
     
        </Slider.Slide>
       

        <Slider.Slide>
     
            <img className='sm:w-16 w-12 ' src={Excel} alt="Excel logo" />
     
        </Slider.Slide>
       






       
    </Slider>
    </div>
  )
}

export default LogoSlider
