import React from 'react'
import our from '../about/images/approch.svg'

const Approach = () => {
  return (
    <div className='rp mb-24  flex max-lg:flex-col-reverse lg:flex-row justify-center items-center '>
    <div className='  w-full sm:m-0 mb-3  '>
      <img src={our} alt="" className=' w-[80%] mx-auto  ' />
    </div>
    <div className=' '>
      <h1 className='sm:text-5xl text-2xl pb-8 text-LightSky text-center uppercase font-bold'>our Approach</h1>
      <p className=' sm:text-lg text-base text-[#a6a6a6] '>
      We're all about tech, but we don't take ourselves too seriously. No jargon or buzzwords here – we keep things simple. We'll explain complicated tech stuff to you as if you were five years old, without being patronizing. Our values? Transparency, honesty, and providing IT solutions that make sense to you.
      </p>
   
    </div>
    
  </div>
  )
}

export default Approach
