import React from 'react'
import header from '../../assets/team/bckg.jpg'
function TeamsImage() {
  return (
    <div>
      <div className=" w-full relative">
        <div className="absolute  inset-0  bg-opacity-50">
          <div className='absolute w-full h-[550px] bg-gradient-to-b from-bgBlue'></div>


          <div className='absolute flex flex-col justify-center items-center w-full  top-[10%] break:top-[5%]'>
            <div className=" absolute bg-gradient-to-r from-bgBlue w-full via-bgBlue py-5 rounded-md  bg-opacity-5 transition-all duration-500">

              <h1 className="text-cherryRed  md:text-5xl font-bold p-5 w-full text-center">Meet Our Team</h1>

            </div>


          </div>
        </div>
        <img src={header} alt="header" className=' w-full ' />


      </div>
    </div>
  )
}

export default TeamsImage
