import React from 'react'
import about from '../about/images/aboutussvg.svg'

const Hero = () => {
  return (
    <div className='rp mb-24 md:flex flex-row gap-2 mt-10 '>
    <div className=' md:w-1/2 w-full sm:flex flex-col justify-center items-center'>
      <h1 className=' pb-8 text-LightSky uppercase text-4xl text-center sm:text-5xl font-bold'>About Us</h1>
      <p className='sm:text-lg text-base text-DarkGray sm:mb-0 mb-10'>
      Welcome to Perceptions Technologies. We're not your usual IT company. Think of us as tech enthusiasts who also love coffee and tea. </p>
    </div>
    <div className=' md:w-2/3 w-full flex justify-center sm:m-0 mb-3'>
       <img src={about} alt="About" className='md:w-[80%] w-[80%] item-center' /> 
    </div>
  </div> 
  )
}

export default Hero
