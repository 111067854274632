import React from 'react'
import AppDev from '../../assets/servicemain/AppDev.json'
import WebDev from '../../assets/servicemain/WebDev.json'
import DM from '../../assets/servicemain/DigitalMarketing.json'
import UiUx from '../../assets/servicemain/UiUx.json'
import Blockchain from '../../assets/servicemain/Blockchain.json'
import DA from '../../assets/servicemain/DataAnalytics.json'
import { Link } from 'react-router-dom'
import Lottie from 'lottie-react'

function SixServices() {
  const ScrollTop = () => {
    window.scrollTo(0,0);
  }
  return (
    <div className='rp'>

      <div className='sm:flex items-center gap-x-10 sm:mb-0 mb-10'>
        <div className='basis-1/2 sm:mb-0 mb-5'>
        <h1 className=' lg:text-3xl sm:text-left text-center font-semibold uppercase md:text-xl sm:text-base  mb-5 text-Summer'>App Development</h1>
        <p className='mb-5 lg:text-base max-lg:text-sm'>With a focus on cutting-edge technologies and agile development methodologies, our Application Development services are designed to cater to a diverse range of industries and business needs.</p>
       <Link to='/AppDev'> <button onClick={ScrollTop} className="servicespagebutton">
  Know More
</button></Link>

        </div>
        <Lottie className='w-[60%] mx-auto' animationData={AppDev}/>

      </div>
      
      <div className='sm:flex items-center gap-x-10  sm:mb-0 mb-10'>
        <div className='basis-1/2 '>
        <h1 className=' lg:text-3xl sm:text-left text-center font-semibold uppercase md:text-xl sm:text-base  mb-5 text-Summer'>Web Development</h1>
        <p className='mb-5 lg:text-base max-lg:text-sm'>Our Website/Software Development services are designed to cater to businesses of all sizes, from start-ups to established enterprises.</p>
        <Link to='/Web'> <button onClick={ScrollTop} className="servicespagebutton">
  Know More
</button></Link>

        </div>
        <Lottie className='w-3/4 mx-auto' animationData={WebDev}/>

      </div>

      <div className='sm:flex items-center gap-x-10 sm:mb-24 mb-10'>
        <div className='basis-1/2 sm:mb-0 mb-5'>
        <h1 className=' lg:text-3xl sm:text-left text-center font-semibold uppercase md:text-xl sm:text-base  mb-5 text-Summer'>Ui / Ux</h1>
        <p className='mb-5 lg:text-base max-lg:text-sm'>Our Digital Marketing services are tailored to help you establish a strong online presence, reach your target audience effectively, and drive tangible results.</p>
       <Link to='/UiUx'> <button onClick={ScrollTop} className="servicespagebutton">
  Know More
</button></Link>


        </div>
        <Lottie className='w-3/4 mx-auto' animationData={UiUx}/>

      </div>


      <div className='sm:flex items-center gap-x-10 mb-20'>
        <div className='basis-1/2 sm:mb-0 mb-10'>
        <h1 className=' lg:text-3xl sm:text-left text-center font-semibold uppercase md:text-xl sm:text-base  mb-5 text-Summer'>Digital Marketing</h1>
        <p className='mb-5 lg:text-base max-lg:text-sm'>Our Digital Marketing services are tailored to help you establish a strong online presence, reach your target audience effectively, and drive tangible results.</p>
        <Link to='/Digital'> <button onClick={ScrollTop} className="servicespagebutton">
  Know More
</button></Link>

        </div>

        <Lottie className='w-3/4 mx-auto' animationData={DM}/>
      </div>


      <div className='sm:flex items-center gap-x-10 sm:mb-0 mb-10'>
        <div className='basis-1/2 sm:mb-0 mb-5'>
        <h1 className=' lg:text-3xl sm:text-left text-center font-semibold uppercase md:text-xl sm:text-base  mb-5 text-Summer'>Blockchain</h1>
        <p className='mb-5 lg:text-base max-lg:text-sm'>We build custom blockchain solutions tailored to your unique needs. Whether it is a private blockchain for your business or a decentralized applications.</p>
        <Link to='/Blockchain'> <button onClick={ScrollTop} className="servicespagebutton">
  Know More
</button></Link>

        </div>
        <Lottie className='w-3/4 mx-auto' animationData={Blockchain}/>

      </div>

      <div className='sm:flex items-center gap-x-10 mb-10'>
        <div className='basis-1/2 sm:mb-0 mb-5'>
        <h1 className=' lg:text-3xl sm:text-left text-center font-semibold uppercase md:text-xl sm:text-base  mb-5 text-Summer'>Analytics & Bots</h1>
        <p className='mb-5 lg:text-base max-lg:text-sm'>We don't do one-size-fits-all. We tailor analytics and bot solutions to meet your unique business needs.</p>
        <Link to='/AnalyticsBots'> <button onClick={ScrollTop} className="servicespagebutton">
  Know More
</button></Link>

        </div>

        <Lottie className=' w-[60%] mx-auto' animationData={DA}/>
      </div>









  









      
    </div>
  )
}

export default SixServices
