import React from 'react'
import DigitalHero from '../components/digital/DigitalHero'
import DigitalContent from '../components/digital/DigitalContent'
import { Helmet } from 'react-helmet'

function Digital() {
  return (
    <div>
       <Helmet>
      <title>
        Digital Marketing - Perceptions Technologies
      </title>
    </Helmet>
      <DigitalHero/>
      <DigitalContent/>
    </div>
  )
}

export default Digital
