import React from 'react'
import improvement from '../../../../assets/services/Wavy_Bus-31_Single-09_prev_ui.png'

function UiUxParaOne() {
  return (
    <div className='  mt-20 flex justify-center items-center  sm:mb-80 mb-10 '>
        <div className=' text-center sm:h-72 rp  '>
             <div className=' grid grid-cols-1 md:grid-cols-2  bg-[#222222]'>
              <div className='p-2 md:p-5 md:mx-12 flex justify-center items-center '>
                <h2 className='text-left '>We believe in Continuous Improvement. Even after launch, we monitor user behavior and gather Feedback to optimize the design further, keeping your digital product ahead of the curve.</h2>
              </div>
              <div className=''>
                <img src={improvement} alt="improve" className='' />
            </div>

             </div>
        </div>

    </div>
  )
}

export default UiUxParaOne
