import React from 'react'
import whyus from '../about/images/why choose us.svg'

const Whyus = () => {
  return (
    <div className=' rp mt-32 mb-24 '>
        <div className='  flex max-lg:flex-col lg:flex-row gap-2 '>
            <div className=' flex flex-col sm:mb-0 mb-10'>
                <h1 className=' sm:text-5xl text-2xl pb-8 text-LightSky text-start  uppercase font-bold'>
                Why choose us?
                </h1>
                <p className=' sm:text-lg text-base text-[#a6a6a6] '>
                Welcome to Perceptions Technologies. You have a choice: The traditional IT path or us. Here, every meeting sparks creativity, making IT exciting.     </p>
                <p className=' sm:mt-4 mt-3 sm:text-lg text-base text-[#a6a6a6]'>
                We're a dynamic, youthful team, far from corporate norms. Unconventional yet tech-savvy partners on your digital journey.
                </p>
                <p className=' sm:mt-4 mt-3 sm:text-lg text-base text-[#a6a6a6]'>
                Join us at Perceptions Technologies, where we're passionate about your project and late-night coding. Your dreams are our focus. 
                </p>
            </div>
            <div className='  w-full flex justify-center sm:m-0 mb-3 '>
                <img src={whyus} alt="" className="md:w-[70%] w-full item-center" />
            </div>
        </div>
  </div>
  )
}

export default Whyus
