import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
function ContactUs() {
  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
    .sendForm('service_nc1exfa', 'template_3b4w55j', form.current, 'iZTMrWTaaWBTQ83BA')
    .then(
      (result) => {
        toast.success('Form submitted successfully!');
        setTimeout(() => {
          navigate('/');
        }, 2000);
      },
      (error) => {
        console.error(error);
        toast.error('Failed to submit the form. Please try again.');
      }
    );

  e.target.reset();
};
  return (
<>
<Helmet>
      <title>
        Contact Us - Perceptions Technologies
      </title>
    </Helmet>
    <div className='rp  mt-8 mb-16  flex flex-col items-center'>
      <h1 className='sm:text-3xl text-xl mb-2 text-center'>
        Get Started with <span className='text-Summer'>Perceptions Technologies</span>
      </h1>
      <p className='text-DarkGray mb-5 text-center'>
        We can't wait to chat with you. Tell us what you're looking for.
      </p>

      <div className='text-black bg-gradient-to-r from-gray-600 via-gray-700 to-gray-900 p-6 rounded-md shadow-md w-full max-w-lg'>
        <form id="form" ref={form} onSubmit={sendEmail}>
          <div className='mb-4'>
            <label htmlFor='user_name' className='block text-sm font-medium text-white'>
              Name <span className='text-red-500'>*</span>
            </label>
            <input
              type='text'
              id='user_name'
              name='user_name'
              required
              autoComplete='name'
              className='mt-1 p-2 border rounded-md w-full bg-gray-300'
              placeholder='Enter your name'
              />
          </div>
          <div className='mb-4'>
            <label htmlFor='user_email' className='block text-sm font-medium text-white'>
              Email <span className='text-red-500'>*</span>
            </label>
            <input
              type='email'
              id='user_email'
              name='user_email'
              required
              autoComplete='user_email'
              className='mt-1 p-2 border rounded-md w-full bg-gray-300'
              placeholder='Enter your E-mail'
              />
          </div>
          <div className='mb-4 sm:flex gap-10'>
            <div className=' basis-[25%] mb-4'>
          <label htmlFor='user_code' className='block text-sm font-medium text-white'>
              Country Code <span className='text-red-500'>*</span>
            </label>
            <input
              type='tel'
              id='user_code'
              name='user_code'
              required
          
              className='mt-1 p-2 border rounded-md w-full bg-gray-300'
              placeholder='Enter code'
              />
              </div>
              <div className=' basis-[75%]'>
            <label htmlFor='user_number' className='block text-sm font-medium text-white'>
              Contact <span className='text-red-500'>*</span>
            </label>
            <input
              type='tel'
              id='user_number'
              name='user_number'
              required
              autoComplete='user_number'
              className='mt-1 p-2 border rounded-md w-full bg-gray-300'
              placeholder='Enter your mobile number'
              /></div>
          </div>
          <div className='mb-4'>
  <label htmlFor='user_service' className='block text-sm font-medium text-white'>
    Service <span className='text-red-500'>*</span>
  </label>
  <select
    id='user_service'
    name='user_service'
    required
    autoComplete='user_service'
    className='mt-1 p-2 border rounded-md w-full bg-gray-300'
  >
    <option value=''>Select a Service</option>
    <option value='App_Development'>App Development</option>
    <option value='Web_Development'>Web Development</option>
    <option value='UI/UX'>UI/ UX</option>
    <option value='Analytics_&_Bots'>Analytics & Bots</option>
    <option value='Blockchain'>Blockchain</option>
    <option value='Digital_Marketing'>Digital Marketing</option>
  </select>
</div>

          <div className='mb-4'>
            <label htmlFor='user_message'  className='block text-sm font-medium text-white'>
              Message
            </label>
            <textarea
              id='user_message'
              name='user_message'
              rows='2'
              autoComplete='message'
              className='mt-1 p-2 border rounded-md w-full bg-gray-300'
              placeholder='Enter your message'
              ></textarea>
          </div>
          <div className='text-center'>
            <button
              type='submit' value="Send"
              className='bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600'
              >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
    <ToastContainer position="top-center" autoClose={3000} />
              </>
  );
}

export default ContactUs;