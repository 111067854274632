import React, { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
// import { FaArrowUp } from "react-icons/fa";
// import { FaRegArrowAltCircleUp } from "react-icons/fa";
// import { MdDoubleArrowUp } from "react-icons/bs";
import { RiArrowUpDoubleFill } from "react-icons/ri";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const goToBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const listenToScroll = () => {
    let heightToHidden = 20;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {isVisible && (
          <div className="top-btn mr-0 ml-[40%]" onClick={goToBtn}>
            < RiArrowUpDoubleFill className="top-btn--icon w-8 font-bold " />
          </div>
        )}
      </Wrapper>
    </ThemeProvider>
  );
};

const theme = {
  colors: {
    btn: "#1E40AF", 
  },
};

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .top-btn {
    font-size: 2.4rem;
    width: 3rem;
    height: 3rem;
    color: #fff;
    background-color: ${({ theme }) => theme.colors.btn};
    box-shadow: ${({ theme }) => theme.colors.shadow};
    border-radius: 50%;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &--icon {
      // animation: gototop 1.25s linear infinite alternate-reverse;
    }

    @keyframes gototop {
      0% {
        transform: translateY(-0.70rem);
      }
      100% {
        transform: translateY(0.70rem);
      }
    }
  }
`;

export default ScrollToTop;
