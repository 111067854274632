import React from 'react'

const BlockchainServices = () => {
  return (
    <div className='rp flex flex-col justify-center items-center'>
      <p className=' sm:text-5xl text-2xl text-LightSky uppercase font-bold'>
      Our Blockchain Services
      </p>
      <p className=' sm:text-lg text-base mt-4'>
      At<span className=' font-bold text-DarkGray'> Perceptions Technologies</span> , we are pioneers in harnessing the power of blockchain technology. 
      </p>
      <p className=' uppercase sm:text-xl text-lg text-DarkGray sm:mt-4 mt-3'>
      Our services include:
      </p>
    </div>
  )
}

export default BlockchainServices
