import React from 'react'
import { BiLogoLinkedinSquare } from 'react-icons/bi'
import { BiLogoInstagramAlt } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import logo from '../../assets-insightsnest/FOOTER-LOGO.png'

const Footer = () => {
  const Top = () => {
    window.scrollTo(0, 0);
  }

  return (
    <div className=' mt-11 rp text-black bg-[#FAF9F6] '>
      <div className=' grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-9 gap-x-12'>
        <div className='flex'>
          <img src={logo} alt="logo" className=' h-24 p-4' />
        </div>
        <div className='flex '>
          <div>

            <h2 className='py-5 font-semibold text-xl text-black font-Inter'>Quick Links</h2>
            <Link to='/insightsnest'><button onClick={Top} className=' text-sm w-full text-start hover:text-blue-500 py-1'>Home</button></Link>
            <Link to='/insightsnest/about'><button onClick={Top} className=' text-sm w-full text-start hover:text-blue-500 py-1'>About</button></Link>
            <Link to='/insightsnest/services'><button onClick={Top} className=' text-sm w-full text-start hover:text-blue-500 py-1'>Services</button></Link>
            {/* <Link to='/case-study'><button onClick={Top} className=' text-sm w-full text-start hover:text-white py-1'>Case Study</button></Link> */}
            <Link to='/insightsnest/contactus'><button onClick={Top} className=' text-sm w-full text-start hover:text-blue-500 py-1'>Contact Us</button></Link>
            {/* <Link to='/teams'><button onClick={Top} className=' text-sm w-full text-start hover:text-white py-1'>Teams</button></Link> */}
          </div>

        </div>
        <div className='flex '>
          <div>
            <h2 className='py-5 font-semibold text-xl text-black font-Inter'>Important Links</h2>
            <Link to='/insightsnest'><button onClick={Top} className=' text-sm w-full text-start hover:text-blue-500'>Terms and Conditions</button></Link>


          </div>
        </div>
        <div className='sm:flex break-words'>
          <div>
            <h2 className='py-4 font-semibold text-xl text-black font-Inter'>Let's work together</h2>
            <p className=' text-sm '>Write to us at:</p>
            <p className='text-sm break-words'>
              <a href="mailto:insightsnest@gmail.com">insightsnest@gmail.com</a>
            </p>


            <div className='flex mt-4'>

              <Link to={'https://www.linkedin.com/showcase/insightsnest'} target="_blank" aria-label="LinkedIn Profile" rel="noopener noreferrer">   <BiLogoLinkedinSquare className=' h-9 w-9 hover:text-blue-500 ' /></Link>
              <Link to={'https://www.instagram.com/insightnest.20'} target="_blank" aria-label="Instagram Profile" rel="noopener noreferrer">  <BiLogoInstagramAlt className=' h-9 w-9 hover:text-blue-500' /></Link>
            </div>

          </div>
        </div>
      </div>

      <div className=" border-t-2 border mt-16 "></div>

      <div className='col-span-1 md:col-span-4 md:col-start-3 md:order-2 text-center md:text-right py-4'>
        <p className='text-whitefooter text-right text-[10px]  mr-1 font-Josefin'>
          Copyright 2024 © | All Rights Reserved | Privacy Policy
        </p>
      </div>
    </div>
  )
}

export default Footer
