import React from 'react'
import Lottie from 'lottie-react'
// import prototype from '../../../../assets/services/prototype.json'

import app from '../../../../assets/services/app.json'
// import workflow from '../../../../assets/services/workflow.png'
import './style.css'

function UiUxContentMiddle() {
  return (
    <div className=' rp flex justify-center items-center sm:mb-56 mb-16'>
        <div className=' grid grid-cols-1 md:grid-cols-2 mt-28 gap-x-40'>
            <div className='flex justify-center items-center sm:mb-0 mb-16 '>
                <Lottie animationData={app} />
            </div>
            <div>
                <div className='grid grid-cols-1 '>
                    <div className='sm:mb-0 mb-10'>
                        <div className='rounded-lg   p-2'>
                            
                            
                           
                            {/* <Lottie className=' w-40 bg-black text-center' animationData={prototype} /> */}
                            
                            <div>
                                <div className='flex justify-center items-center'>
                                <h2 className='text-center text-[#18B7F6]  text-xl font-Inter sm:mb-0 mb-10 '>Wireframing and Prototyping</h2>
                                
                                </div>
                            
                                <h3 className='text-center text-sm md:py-5 text-Whitefooter'>Before the development phase, we create wireframes and interactive prototypes to visualize the flow and functionality of your digital product. This iterative process allows us to refine the design until it's perfect.
                                </h3>
                            </div>
                            </div>
                              
                            
                        
                    </div>
                    <div>
                        <div className='rounded-lg  p-2'>
                          
                           
                          
                            {/* <Lottie className=' w-40 rounded-3xl  text-center' animationData={responsive} /> */}
                            
                            <div>
                            <h2 className='text-center text-[#18B7F6]  text-xl font-Inter sm:mb-0 mb-10 '>Accessibility and Inclusivity</h2>
                                <h3 className='text-left text-sm md:py-5 text-Whitefooter'>
                                In today's multi-device world, a responsive design is crucial. We ensure that your digital product looks and functions flawlessly across various screen sizes and devices, providing a consistent experience to all users.
                                    
                                </h3>
                            </div>
                            
                        </div>     
                    </div>

                </div>

            </div>

        </div>
      
    </div>
  )
}

export default UiUxContentMiddle
