import React from 'react'
import Lottie from 'lottie-react'
import customerAnalysis from '../../../../assets/services/userResearch.json'
import ui from '../../../../assets/services/ui.json'
import userExperience from '../../../../assets/services/userExperience.json'
import './style.css';

function UiUxContentStart() {
  return (
    <div className=' flex justify-center items-center rp sm:mb-20 mb-5 '>
       <div className='p-2'>
       
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-y-11 gap-x-0 sm:gap-x-11 mt-10' >

          <div className='rounded-lg py-10 cardcol'>

            <div className='flex justify-center items-center '>
            <Lottie className=' w-36 ' animationData={customerAnalysis} />
            
            </div>
            
            <h2 className='text-center text-[#18B7F6]  text-xl text-inter pt-3'>User Research and Analysis</h2>
            <h3 className='text-center text-sm  text-Whitefooter '>Understanding your users is the key to building exceptional experiences. Our team conducts in-depth user research and analysis to gain valuable insights into your target audience's behaviors, preferences, and pain points.
            </h3>
          
          </div>
          <div className='rounded-lg  cardcol '>
            <div className='flex justify-center items-center'>
            <Lottie className='w-53 p-3 bg-black' animationData={ui} />
            </div>
            
            <h2 className='text-center text-[#18B7F6]  text-xl text-Inter '>User Interface (UI) Design</h2>
            <h3 className='text-center text-sm  text-Whitefooter'>Our creative UI designers translate insights into stunning visual designs that captivate users from the moment they land on your platform. We focus on creating intuitive interfaces that guide users seamlessly through their journey.
            </h3>
          
          </div>
          <div className='rounded-lg  cardcol'>
            <div className='flex justify-center items-center'>
            <Lottie className=' w-48 p-2 bg-black' animationData={userExperience} />
            </div>
            
            <h2 className='text-center text-[#18B7F6]  text-xl  '>User Experience (UX) Design</h2>
            <h3 className='text-center text-sm  text-Whitefooter'>With a user-centric approach, our UX designers craft experiences that leave a lasting impact. We optimize every interaction and touchpoint, ensuring that your digital product is not only easy to use but also emotionally engaging.</h3>
          
          </div>
          

        </div>
        </div>
    </div>
  )
}

export default UiUxContentStart
