import React from 'react'
import { Link } from 'react-router-dom'
// import blockchain from '../../../../assets/home/slider/Blockchain.png'


function BlockchainRevolution(){
  const ScrollTop = () => {
    window.scrollTo(0,0);
  }
//   const pict = [
//     blockchain
// ];
  return (
    <div className=' rp '>
        <div className=' shadow-md shadow-LightSky p-4 mb-16 sm:mb-10'>
        <p className='  text-LightSky uppercase text-xl'>
        Join the<span> Blockchain Revolution</span> 
        </p>
        <p className=' sm:text-lg text-base mt-2 text-DarkGray'>

        Blockchain is reshaping the digital landscape, and we are here to guide you through 
        this exciting journey. Whether you are a blockchain enthusiast or just curious about 
        its potential, Perceptions Technologies is your partner in embracing this transformative 
        technology.
        </p></div>
        {/* <div className=' rp flex justify-center items-center gap-8'>
         {pict.map((image, index)=>(
          <img src={image} key={index} alt="gdeejhg" className='sm:w-20 w-12' />
          ))}
    </div> */}

        <div className='sm:flex justify-between mb-10 '>

<Link to='/Digital'>  <button onClick={ScrollTop} className=" mb-10 bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition-all duration-500 ease-in-out hover:scale-110 hover:brightness-110 hover:animate-pulse active:animate-bounce">
Previous Service
</button></Link>

<Link to='/AnalyticsBots'>  <button onClick={ScrollTop} className="  bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition-all duration-500 ease-in-out hover:scale-110 hover:brightness-110 hover:animate-pulse active:animate-bounce">
Next Service
</button></Link>
</div>

      </div>
  )
}

export default BlockchainRevolution
