import React from 'react'
import CaseStudyitems from './CaseStudyitems'
// import pikchar from '../casestudy/images/Pikchar.png'
import video from '../casestudy/images/video-player.png'
import zeaditech from '../casestudy/images/Zeaditech.png'
import nautilus from '../casestudy/images/Nautilus.png'
import streamline from '../casestudy/images/Streamline.jpg'
import balaji_caterting from '../casestudy/images/Balaji Caterting.png'
import ekaiv from '../casestudy/images/Ekaiv.png'

export const StudyFields = [
  {
   id: 1,
   cover: ekaiv,
   title:"The Ekaiv Empowering Indian Creativity",
   description:"The Ekaiv is a revolutionary e-commerce platform that is dedicated to unleash the untapped potential of individuals across India by providing them with a space to showcase their unique talents and creativity. Serving as a dynamic marketplace.It’s a place where individuals can display what they make and do, helping them embrace entrepreneurship while growing professionally. Additionally, we provide support from the initial stages to their journey to success. ",

   Challenges:" Challenges ",
    challenge_heading_1: " ",
     challenge_desc_1: " The process for users to join and utilize The Ekaiv platform may be complicated, with technical complexities presenting obstacles to smooth onboarding and utilization.",

    challenge_heading_2: " ",
     challenge_desc_2: "Users may lack the necessary skills and knowledge to succeed in the ecommerce landscape, with limited access to educational resources and training programs.",

    challenge_heading_3: " ",
     challenge_desc_3: "Users may struggle with product listing, inventory management and order processing.",

    challenge_heading_4: " ",
     challenge_desc_4: " Many users, especially those to new e-commerce, struggle with the complex onboarding process on The Ekaiv due to their lack of familiarity with e-commerce practices.     ",

    challenge_heading_5: " ",
     challenge_desc_5: "",

   solution: "Sloution ",
   solution_heading_1: " ",
   solution_desc_1: "Providing access to resources such as tutorials, guides and customer support to assist users in navigating technical challenges effectively. ",

  solution_heading_2: " ",
  solution_desc_2: "Simplifying the onboarding process while providing comprehensive guidance and support to its user for understanding the complexities of e-commerce. ",

  solution_heading_3: " ",
  solution_desc_3: " Streamlining the process for users to join and utilize the Ekaiv, reducing technical complexities, and offering comprehensive guidance.",

  solution_heading_4: " ",
  solution_desc_4: "Offering educational resources and training programs to equip users with necessary skills and knowledge to succeed in the E-commerce landscape. ",

  solution_heading_5: " ",
  solution_desc_5: " Offering user-friendly, integrated tools within The Ekaiv platform for easy product listing, inventory management and order processing, minimizing the need for external resources.",

   objectives: "Objectives ",
   objective_heading_1: " Empowering Creativity:",
   objective_desc_1: "The Ekaiv provides a user-friendly platform for individuals to create profiles and showcase their talents, whether it be handmade crafts, artworks, clothes, food and many more. ",

  objective_heading_2: "Amplifying Visibility: ",
   objective_desc_2: "Through strategic marketing initiatives and social media campaigns, The Ekaiv enhances the visibility of seller’s products, reaching a wider audience and driving sales. ",

  objective_heading_3: " Simplified E-commerce:",
   objective_desc_3: " The Ekaiv simplifies the e-commerce process, offering easy-to-use tools for product listing, inventory management and order processing, empowering sellers to focus on their creativity.",

  objective_heading_4: " ",
   objective_desc_4: " In addition to empowering creativity and simplifying e-commerce processes, the Ekaiv goes above and beyond by offering a comprehensive range of facilities, This includes professional services such as chartered accountants, lawyers, photographer, digital marketing, business analytics, business registration and training and development as per their requirements. The Ekaiv ensures that users have all the support they need to thrive and succeed in their journey with The Ekaiv.",

  objective_heading_5: " ",
   objective_desc_5: " ",

   result: " Result ",
   result_heading_1: " ",
   result_desc_1: "The Ekaiv platform has provided talented individuals with a broader audience, increasing exposure for their products and talents. ",

  result_heading_2: " ",
  result_desc_2: "Sellers on The Ekaib have experienced economic empowerment through increased sales and revenue generation, contributing to their financial independence and growth.  ",

  result_heading_3: " ",
  result_desc_3: " The Ekaiv has played a role in preserving and promoting India;s rich cultural heritage by showcasing traditional crafts and artwork, thus supporting local artisans and craftsmen.",

  result_heading_4: " ",
   result_desc_4: " ",

  result_heading_5: " ",
  result_desc_5: " ",

  conclusion: " Conclusion",
  conclusion_desc:"Ekaive's e-commerce platform has successfully addressed the challenges faced by talented individuals in India, providing them with a platform to showcase their creativity, grow professionally, and contribute to India's cultural and economic landscape. By empowering entrepreneurship and fostering a supportive community, Ekaive is playing a pivotal role in unlocking the potential of India's hidden talents. ",
},

{
  id: 2,
  cover: balaji_caterting,
  title:"Balaji Caterting",
  description:"In the digital age, an outdated website can hinder business growth. Balaji Caterings, a reputable catering service, faced this challenge with their obsolete online platform. Collaborating closely with our team, they embarked on a journey to revamp their website, focusing on modern design and improved user experience. Through strategic implementation of HTML and CSS, we transformed their online presence, leading to enhanced user engagement, visibility, and conversions. This case study highlights the pivotal role of a revamped website in driving success in the digital landscape. ",

  Challenges:" Challenges ",
   challenge_heading_1: " ",
    challenge_desc_1: " Balaji Caterings, a reputable catering service, faced challenges with an outdated website that failed to effectively showcase their offerings and services. The site lacked modern design elements, intuitive navigation, and mobile responsiveness.",

   challenge_heading_2: " ",
    challenge_desc_2: "",

   challenge_heading_3: " ",
    challenge_desc_3: "",

   challenge_heading_4: " ",
    challenge_desc_4: "  ",

   challenge_heading_5: " ",
    challenge_desc_5: "",

  solution: "Sloution ",
  solution_heading_1: " ",
  solution_desc_1: "Our team collaborated closely with Balaji Caterings to understand their goals and requirements. We proposed a comprehensive website revamp focusing on modern design, improved user experience, and mobile responsiveness. Utilizing HTML and CSS, we crafted a visually appealing layout with easy navigation and optimized content structure. ",

 solution_heading_2: " ",
 solution_desc_2: " ",

 solution_heading_3: " ",
 solution_desc_3: " ",

 solution_heading_4: " ",
 solution_desc_4: "",

 solution_heading_5: " ",
 solution_desc_5: " ",

  objectives: " ",
  objective_heading_1: " ",
  objective_desc_1: "",

 objective_heading_2: " ",
  objective_desc_2: " ",

 objective_heading_3: " ",
  objective_desc_3: " ",

 objective_heading_4: " ",
  objective_desc_4: " ",

 objective_heading_5: " ",
  objective_desc_5: " ",

  result: " Result ",
  result_heading_1: "Enhanced User Experience:  ",
  result_desc_1: " The modern design and intuitive navigation resulted in increased user engagement and longer session durations. ",

 result_heading_2: "Improved Visibility:  ",
 result_desc_2: "Through SEO optimization and content enhancements, Balaji Caterings experienced higher search engine rankings, leading to increased organic traffic. ",

 result_heading_3: "Positive Feedback:  ",
 result_desc_3: " Clients praised the revamped website for its user-friendly interface and seamless browsing experience.",

 result_heading_4: "Increased Conversions:  ",
  result_desc_4: " The optimized website facilitated streamlined booking processes, resulting in a notable uptick in inquiries and conversions. ",

 result_heading_5: " ",
 result_desc_5: " ",

 conclusion:" Conclusion",
 conclusion_desc:"The HTML and CSS-based website revamp successfully addressed Balaji Caterings' challenges, resulting in a modern, user-friendly online platform that effectively showcases their services. The collaboration between our team and Balaji Caterings led to improved visibility, enhanced user experience, and increased conversions, positioning them as a leading catering service in the region. ",
  

},

{
  id: 3,
  cover: zeaditech,
  title:"Zeaditech - Empowering Mobile Individuals",
  description:" Zeaditech embarked on its journey with a vision to simplify complex problems faced by mobile individuals, utilizing cutting-edge technology and design principles. From its humble beginnings as a startup, Zeaditech has grown into a formidable presence in the industry.",

  Challenges:" Challenges ",
   challenge_heading_1: " ",
    challenge_desc_1: " Throughout its evolution, Zeaditech encountered various challenges, each of which served as a catalyst for innovation and growth. These obstacles ranged from technical hurdles to market competition but only strengthened the company's resolve to deliver exceptional software solutions.",

   challenge_heading_2: " ",
    challenge_desc_2: "",

   challenge_heading_3: " ",
    challenge_desc_3: "",

   challenge_heading_4: " ",
    challenge_desc_4: "  ",

   challenge_heading_5: " ",
    challenge_desc_5: "",

  solution: "Sloution ",
  solution_heading_1: " ",
  solution_desc_1: " Zeaditech's commitment to excellence and innovation led to the development of software solutions that not only addressed the challenges faced by mobile individuals but also delivered tangible, measurable results for clients. By leveraging the latest technology and design trends, Zeaditech continues to provide cutting-edge solutions that exceed client expectations.",

 solution_heading_2: " ",
 solution_desc_2: " ",

 solution_heading_3: " ",
 solution_desc_3: " ",

 solution_heading_4: " ",
 solution_desc_4: "",

 solution_heading_5: " ",
 solution_desc_5: " ",

  objectives: "",
  objective_heading_1: " ",
  objective_desc_1: "",

 objective_heading_2: " ",
  objective_desc_2: " ",

 objective_heading_3: " ",
  objective_desc_3: " ",

 objective_heading_4: " ",
  objective_desc_4: " ",

 objective_heading_5: " ",
  objective_desc_5: " ",

  result: " Result ",
  result_heading_1: "Innovation:  ",
  result_desc_1: "Zeaditech's innovative approach has led to the creation of software solutions that are at the forefront of the industry, setting new standards for excellence. ",

 result_heading_2: "Client Satisfaction:  ",
 result_desc_2: "The company's dedication to delivering exceptional results has resulted in high levels of client satisfaction and long-term partnerships. ",

 result_heading_3: "Market Impact:  ",
 result_desc_3: " Zeaditech's solutions have made a significant impact on the market, empowering mobile individuals and driving positive change in various industries. ",

 result_heading_4: "Growth: ",
  result_desc_4: " Despite challenges, Zeaditech has experienced steady growth, expanding its reach and influence in the industry. ",

 result_heading_5: " ",
 result_desc_5: " ",

 conclusion: " Conclusion",
   conclusion_desc: "Zeaditech's journey from a small startup to a dynamic force in the industry is a testament to its commitment to innovation, excellence, and client satisfaction. By consistently delivering exceptional software solutions, Zeaditech continues to empower mobile individuals and drive positive change in the digital landscape. ",
  

},

{
  id: 4,
  cover: video,
  title:" Video Player - Your Ultimate Destination",
  description:" Video player emerged with a bold vision to redefine the Over-The-Top (OTT) entertainment landscape by offering a comprehensive platform where users can access a vast array of content seamlessly. From its inception, Video player aimed to become the go-to destination for entertainment enthusiasts seeking convenience and variety. ",

  Challenges:" Challenges ",
   challenge_heading_1: " ",
    challenge_desc_1: " Entering an already crowded OTT market posed significant challenges for Video player. Competition was fierce, and gaining traction amidst established players required innovative strategies and a compelling value proposition. Additionally, ensuring a diverse and engaging content library while navigating licensing agreements and rights management presented ongoing hurdles.",

   challenge_heading_2: " ",
    challenge_desc_2: "",

   challenge_heading_3: " ",
    challenge_desc_3: "",

   challenge_heading_4: " ",
    challenge_desc_4: "  ",

   challenge_heading_5: " ",
    challenge_desc_5: "",

  solution: "Sloution ",
  solution_heading_1: " ",
  solution_desc_1: " Video player tackled these challenges head-on by prioritizing user experience and content curation. Leveraging advanced algorithms and user data analytics, Video player personalized content recommendations to cater to individual preferences effectively. Furthermore, strategic partnerships with content creators and production houses enabled Video player to offer exclusive, high-quality content to its subscribers.",

 solution_heading_2: " ",
 solution_desc_2: " ",

 solution_heading_3: " ",
 solution_desc_3: " ",

 solution_heading_4: " ",
 solution_desc_4: "",

 solution_heading_5: " ",
 solution_desc_5: " ",

  objectives: " ",
  objective_heading_1: " ",
  objective_desc_1: "",

 objective_heading_2: " ",
  objective_desc_2: " ",

 objective_heading_3: " ",
  objective_desc_3: " ",

 objective_heading_4: " ",
  objective_desc_4: " ",

 objective_heading_5: " ",
  objective_desc_5: " ",

  result: " Result ",
  result_heading_1: " Enhanced User Engagement: ",
  result_desc_1: "Video player's intuitive interface and personalized recommendations resulted in increased user engagement and retention rates. ",

 result_heading_2: "Expansive Content Library: ",
 result_desc_2: "Through strategic partnerships and content acquisitions, Video player assembled a diverse library encompassing movies, TV shows, documentaries, and original productions, catering to a wide audience demographic. ",

 result_heading_3: " Brand Recognition: ",
 result_desc_3: "Despite entering a competitive market, Video player's distinct brand identity and innovative features garnered attention, leading to rapid brand recognition and market penetration. ",

 result_heading_4: " Revenue Growth:  ",
  result_desc_4: "As Video player gained traction, its subscriber base expanded, leading to steady revenue growth and establishing the platform as a profitable venture. ",

 result_heading_5: " ",
 result_desc_5: " ",

 conclusion: " Conclusion",
 conclusion_desc: " Video player's journey exemplifies the power of innovation and strategic vision in navigating the dynamic landscape of OTT entertainment. By prioritizing user experience, content diversity, and strategic partnerships, Video player successfully carved a niche for itself in the highly competitive OTT market, emerging as the ultimate destination for entertainment enthusiasts worldwide.",
  

},

{
  id: 5,
  cover: nautilus,
  title:"Nautilus - Revolutionizing Conversational AI",
  description:" Nautilus embarked on a mission to revolutionize the conversational AI landscape by creating a sophisticated chat-bot capable of providing personalized assistance and support across various domains. With a focus on seamless user interaction and advanced natural language processing capabilities, Nautilus aimed to set new standards in AI-driven customer service. ",

  Challenges:" Challenges ",
   challenge_heading_1: "Natural Language Understanding:",
    challenge_desc_1: "Ensuring the chat-bot could accurately interpret user queries and provide relevant responses.",

   challenge_heading_2: "Integration Complexity: ",
    challenge_desc_2: "Integrating Nautilus seamlessly with existing systems and platforms to deliver a cohesive user experience.",

   challenge_heading_3: "Scalability: ",
    challenge_desc_3: "Designing Nautilus to handle large volumes of concurrent interactions while maintaining performance and responsiveness.",

   challenge_heading_4: " ",
    challenge_desc_4: "  ",

   challenge_heading_5: " ",
    challenge_desc_5: "",

  solution: "Sloution ",
  solution_heading_1: " Advanced NLP Algorithms: ",
  solution_desc_1: "Implementing state-of-the-art natural language processing algorithms to enhance the chat-bot's understanding of user intents and context.",

 solution_heading_2: "Seamless Integration: ",
 solution_desc_2: "Developing robust APIs and integration frameworks to facilitate seamless integration with various platforms and systems, ensuring a consistent user experience.",

 solution_heading_3: "Scalable Architecture:",
 solution_desc_3: "Designing Nautilus with a scalable architecture capable of handling increasing volumes of interactions through efficient resource allocation and load balancing.",

 solution_heading_4: " ",
 solution_desc_4: "",

 solution_heading_5: " ",
 solution_desc_5: " ",

  objectives: "",
  objective_heading_1: "",
  objective_desc_1: "",

 objective_heading_2: "",
  objective_desc_2: "",

 objective_heading_3: "",
  objective_desc_3: "",

 objective_heading_4: "",
  objective_desc_4: "",

 objective_heading_5: "",
  objective_desc_5: "",

  result: " Result ",
  result_heading_1: "Enhanced User Experience:",
  result_desc_1: "Nautilus' advanced NLP capabilities and seamless integration resulted in a superior user experience, with users receiving accurate and timely assistance.",

 result_heading_2: "Increased Efficiency: ",
 result_desc_2: "By automating repetitive tasks and providing instant responses to user queries, Nautilus improved operational efficiency for businesses, reducing response times and enhancing productivity.",

 result_heading_3: "Scalability: ",
 result_desc_3: "Nautilus' scalable architecture enabled it to accommodate growing user demands and handle large volumes of interactions without compromising performance or reliability.",

 result_heading_4: " ",
  result_desc_4: " ",

 result_heading_5: " ",
 result_desc_5: " ",

 conclusion: " Conclusion",
 conclusion_desc: "Nautilus' journey exemplifies the transformative potential of conversational AI in enhancing customer service and user experience. Through advanced NLP algorithms, seamless integration, and scalable architecture, Nautilus has set new benchmarks in AI-driven chat-bot technology, paving the way for more efficient and personalized interactions in various domains.",
  

},

{
  id: 6,
  cover: streamline,
  title:"Streamline ERP - Transforming Business Operations",
  description:"Streamline ERP embarked on a mission to revolutionize business operations by providing a comprehensive Enterprise Resource Planning (ERP) system tailored to the unique needs of modern enterprises. With a focus on integrating core business functions and enhancing operational efficiency, Streamline ERP aimed to empower organizations to achieve their full potential.",

  Challenges:" Challenges ",
   challenge_heading_1: "Legacy Systems Integration: ",
    challenge_desc_1: "Integrating with existing legacy systems and databases to ensure seamless data migration and continuity.",

   challenge_heading_2: "Customization Complexity:",
    challenge_desc_2: "Tailoring the ERP system to accommodate diverse business processes and workflows while maintaining scalability and flexibility.",

   challenge_heading_3: "User Adoption:",
    challenge_desc_3: "Overcoming resistance to change and ensuring widespread user adoption across all levels of the organization.",

   challenge_heading_4: " ",
    challenge_desc_4: "  ",

   challenge_heading_5: " ",
    challenge_desc_5: "",

  solution: "Sloution ",
  solution_heading_1: "Seamless Integration:",
  solution_desc_1: "Developing robust integration modules and APIs to facilitate seamless connectivity with existing systems and databases, ensuring smooth data migration and interoperability.",

 solution_heading_2: "Flexible Customization:",
 solution_desc_2: "Implementing a modular architecture and customizable workflows to allow businesses to adapt the ERP system to their specific requirements without compromising scalability or performance.",

 solution_heading_3: "User-Centric Design: ",
 solution_desc_3: "Prioritizing user experience and usability in the design and development of the ERP system, with intuitive interfaces and comprehensive training programs to encourage user adoption and minimize resistance to change.",

 solution_heading_4: " ",
 solution_desc_4: "",

 solution_heading_5: " ",
 solution_desc_5: " ",

  objectives: " ",
  objective_heading_1: " ",
  objective_desc_1: "",

 objective_heading_2: " ",
  objective_desc_2: " ",

 objective_heading_3: " ",
  objective_desc_3: " ",

 objective_heading_4: " ",
  objective_desc_4: " ",

 objective_heading_5: " ",
  objective_desc_5: " ",

  result: " Result ",
  result_heading_1: "Improved Efficiency",
  result_desc_1: "Streamline ERP's integrated approach streamlined business processes and workflows, eliminating redundant tasks and reducing manual effort, leading to significant improvements in operational efficiency.",

 result_heading_2: "Enhanced Visibility:",
 result_desc_2: "By centralizing data and providing real-time insights into key business metrics, Streamline ERP empowered organizations to make informed decisions and respond proactively to changing market dynamics.",

 result_heading_3: "Scalability:",
 result_desc_3: "Streamline ERP's modular architecture and flexible customization options enabled it to scale seamlessly alongside growing businesses, accommodating evolving needs and requirements without disrupting operations.",

 result_heading_4: " ",
  result_desc_4: " ",

 result_heading_5: " ",
 result_desc_5: " ",

 conclusion: " Conclusion",
 conclusion_desc: "Streamline ERP's journey exemplifies the transformative impact of ERP systems in optimizing business operations and driving organizational growth. By addressing key challenges through seamless integration, flexible customization, and user-centric design, Streamline ERP has emerged as a trusted partner for businesses seeking to streamline their operations and achieve greater efficiency and competitiveness in today's dynamic marketplace.",
  

},

// {
//   id: "",
//   cover: '',
//   title:"",
//   description:" ",

//   Challenges:" Challenges ",
//    challenge_heading_1: " ",
//     challenge_desc_1: " ",

//    challenge_heading_2: " ",
//     challenge_desc_2: "",

//    challenge_heading_3: " ",
//     challenge_desc_3: "",

//    challenge_heading_4: " ",
//     challenge_desc_4: "  ",

//    challenge_heading_5: " ",
//     challenge_desc_5: "",

//   solution: "Sloution ",
//   solution_heading_1: " ",
//   solution_desc_1: " ",

//  solution_heading_2: " ",
//  solution_desc_2: " ",

//  solution_heading_3: " ",
//  solution_desc_3: " ",

//  solution_heading_4: " ",
//  solution_desc_4: "",

//  solution_heading_5: " ",
//  solution_desc_5: " ",

//   objectives: "Objectives ",
//   objective_heading_1: " ",
//   objective_desc_1: "",

//  objective_heading_2: " ",
//   objective_desc_2: " ",

//  objective_heading_3: " ",
//   objective_desc_3: " ",

//  objective_heading_4: " ",
//   objective_desc_4: " ",

//  objective_heading_5: " ",
//   objective_desc_5: " ",

//   result: " Result ",
//   result_heading_1: " ",
//   result_desc_1: " ",

//  result_heading_2: " ",
//  result_desc_2: " ",

//  result_heading_3: " ",
//  result_desc_3: " ",

//  result_heading_4: " ",
//   result_desc_4: " ",

//  result_heading_5: " ",
//  result_desc_5: " ",

//  conclusion: " Conclusion",
//  conclusion_desc: " ",
  

// },

]

const CaseStudyList = ({studys}) => (
    <div className=' mb-36 px-5 grid gap-12 md:grid-cols-3 sm:grid-cols-1 max-w-screen-xl w-11/12 mx-auto py-4'>
      {studys.map(cases=><CaseStudyitems case={cases} key={cases.id} />)}
    </div>
  )

export default CaseStudyList
