import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { StudyFields } from './CaseStudyList';

const CaseStudyPage = () => {
  const { id } = useParams();
  const [page ,setPage] = useState (null);

  useEffect(() => {
    let page = StudyFields.find((page) => page.id === parseInt(id));
    if (page) {
      setPage(page);
    }
  },[id]);


  return (
    <>
    <div className=' mb-36  px-14'>
    <Link className='text-[#0094eb] text-sm font-semibold mb-8 mt-10 block' to='/case-study'>
      <span> ← </span> <span>Go Back</span>
    </Link>
    {page ? (
      <div className='max-w-2xl mx-auto'>
        <header className='text-center'>
          <p className='text-3xl font-bold mb-4'>{page.title}</p>
        </header>
        <img src={page.cover} alt='cover' className='w-full' />
        <p className='p-4 mt-6 '>{page.description}</p>

        {/* Challenges  */}
        <p className='px-4 mt-14 text-2xl font-bold text-start '>{page.Challenges}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.challenge_heading_1}</p>
        <p className='px-4'>{page.challenge_desc_1}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.challenge_heading_2}</p>
        <p className='px-4'>{page.challenge_desc_2}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.challenge_heading_3}</p>
        <p className='px-4'>{page.challenge_desc_3}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.challenge_heading_4}</p>
        <p className='px-4'>{page.challenge_desc_4}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.challenge_heading_5}</p>
        <p className='px-4'>{page.challenge_desc_5}</p>

         {/* solution  */}
        <p className='px-4 mt-14 text-2xl font-bold text-start '>{page.solution}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.solution_heading_1}</p>
        <p className='px-4'>{page.solution_desc_1}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.solution_heading_2}</p>
        <p className='px-4'>{page.solution_desc_2}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.solution_heading_3}</p>
        <p className='px-4'>{page.solution_desc_3}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.solution_heading_4}</p>
        <p className='px-4'>{page.solution_desc_4}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.solution_heading_5}</p>
        <p className='px-4'>{page.solution_desc_5}</p>  

         {/*  objectives  */}
        <p className='px-4 mt-14 text-2xl font-bold text-start '>{page.objectives}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.objective_heading_1}</p>
        <p className='px-4'>{page.objective_desc_1}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.objective_heading_2}</p>
        <p className='px-4'>{page.objective_desc_2}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.objective_heading_3}</p>
        <p className='px-4'>{page.objective_desc_3}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.objective_heading_4}</p>
        <p className='px-4'>{page.objective_desc_4}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.objective_heading_5}</p>
        <p className='px-4'>{page.objective_desc_5}</p> 

         {/* result */}
        <p className='px-4 mt-14 text-2xl font-bold text-start '>{page.result}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.result_heading_1}</p>
        <p className='px-4'>{page.result_desc_1}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.result_heading_2}</p>
        <p className='px-4'>{page.result_desc_2}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.result_heading_3}</p>
        <p className='px-4'>{page.result_desc_3}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.result_heading_4}</p>
        <p className='px-4'>{page.result_desc_4}</p>
        <p className='px-4 mt-6 text-xl font-bold text-start '>{page.result_heading_5}</p>
        <p className='px-4'>{page.result_desc_5}</p>

        <p className='px-4 mt-14 text-2xl font-bold text-start '>{page.conclusion}</p>
        <p className='px-4 mt-1'>{page.conclusion_desc}</p>

        
        
      </div>
    ) : (
        <p>Study not found</p>
    )}
    </div>
  </>
  )
}

export default CaseStudyPage
