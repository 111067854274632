import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from 'react-router-dom';
import 'animate.css';

function ServiceCard() {
  const serviceData = useMemo(() => [
    {
      title: "App Development",
      description: "Turning your app ideas into reality, one pixel at a time.",
      link: "/AppDev",
    },
    {
      title: "Web Development",
      description: "Crafting digital experiences that leave a lasting impression.",
      link: "/Web",
    },
    {
      title: "UI / UX",
      description: "Designing interfaces that feel like home to your users.",
      link: "/UiUx",
    },
    {
      title: "Digital Marketing",
      description: "Connecting your brand with the right audience, right now.",
      link: "/Digital",
    },
    {
      title: "Blockchain",
      description: "Building trust in a decentralized world.",
      link: "/Blockchain",
    },
    {
      title: "Analytics & Bots",
      description: "Smarter analytics and AI-powered bots for better decisions.",
      link: "/AnalyticsBots",
    },
  ], []);

  const [isVisible, setIsVisible] = useState(Array(serviceData.length).fill(false));
  const [isHovered, setIsHovered] = useState(-1);

  const containerRefs = useRef(serviceData.map(() => React.createRef()));

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observers = serviceData.map((_, index) =>
      new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const newIsVisible = [...isVisible];
            newIsVisible[index] = true;
            setIsVisible(newIsVisible);
            observers[index].unobserve(entry.target);
          }
        });
      }, options)
    );

    const currentRefs = containerRefs.current;

    currentRefs.forEach((ref, index) => {
      const currentContainerRef = ref.current;
      if (currentContainerRef) {
        observers[index].observe(currentContainerRef);
      }
    });

    return () => {
      currentRefs.forEach((ref, index) => {
        const currentContainerRef = ref.current;
        if (currentContainerRef) {
          observers[index].unobserve(currentContainerRef);
        }
      });
    };
  }, [isVisible, serviceData]);

  const divStyle = (index) => ({
    transform: isHovered === index ? 'scale(1.10)' : 'scale(1)',
    transition: 'transform 0.3s linear',
  });

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="rp font-Poppins sm:mb-32 mb-16">
      <h1 className='sm:text-5xl text-2xl font-bold pb-8 text-center text-Summer uppercase mb-10'>Services That We Provide</h1>
      <div className="flex w-full  gap-x-20 max-[810px]:gap-x-10 gap-y-14 justify-center flex-wrap">
        {serviceData.map((service, index) => (
          <div
            key={index}
            ref={containerRefs.current[index]}
            className={`servicecard animate__animated ${
              isVisible[index] ? "animate__zoomInDown opacity-1" : "opacity-0"
            }`}
            style={divStyle(index)}
            onMouseEnter={() => setIsHovered(index)}
            onMouseLeave={() => setIsHovered(-1)}
          >
            <h1 className="text-center text-Summer sm:text-xl text-base">
              {service.title}
            </h1>

            <p className="pt-5 pb-9  sm:text-base text-xs">
              {service.description}
            </p>

            <Link to={service.link}>
              <button className="servicebutton" onClick={scrollToTop}>
                Know More
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServiceCard;
