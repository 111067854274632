import React from 'react'
import QA from '../../../../assets/services/QualityAssurance.png'

function WebServicefinal() {
  return (
    <div className='rp sm:mb-32 mb-16 '>
      <div>
        <div className='grid grid-cols-1 md:grid-cols-2 '>
            <div className='flex justify-center items-center sm:mb-0 mb-10'><img  src={QA} alt="Quality Assurance and Testing png"  /></div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div className=''>
                    <h1 className=' text-Summer font-bold text-xl '>Custom Web Application Development</h1>
                    <p className='md:text-base max-md:text-xs text-Whitefooter py-3'>We specialize in creating bespoke web applications that perfectly align with your unique business requirements. Our experienced developers harness the power of the latest technologies to build scalable, secure, and high-performance web solutions.</p>

                </div>
                <div>
                    <h1 className=' text-Summer font-bold text-xl '>Responsive Website Design</h1>
                    <p className='md:text-base max-md:text-sm text-Whitefooter py-3'>In today's mobile-first world, having a responsive website is imperative. Our design team ensures that your website looks stunning and functions flawlessly across all devices, providing an optimal user experience to your visitors.</p>

                </div>
                <div>
                    <h1 className=' text-Summer font-bold text-xl py-2'> E-Commerce Solutions</h1>
                    <p className='md:text-base max-md:text-sm text-Whitefooter py-2'>
                        Maximize your online business potential with our cutting-edge e-commerce solutions. From intuitive product catalogues to seamless payment gateways, we create engaging e-commerce platforms that drive conversions and boost revenue.
                    </p>
                </div>
                <div>
                    <h1 className=' text-Summer font-bold text-xl py-2'>Support and Maintenance</h1>
                    <p className='md:text-base max-md:text-sm text-Whitefooter py-2'>
                     Our partnership extends beyond development. We offer ongoing support and maintenance services to keep your digital assets running smoothly and up to date.
                    </p>
                </div>
                

            </div>

        </div>
      </div>
    </div>
  )
}

export default WebServicefinal
