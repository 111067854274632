import React from 'react'
import CaseStudyHead from './CaseStudyHead'
import CaseStudyList, {StudyFields} from './CaseStudyList'

const CaseStudy = () => {
  return (
    <>
    {/* header  */}
    <CaseStudyHead />

    <CaseStudyList studys={StudyFields} />
    </>
  )
}

export default CaseStudy
