import React from 'react'



const CaseStudyHead = () => {
  return (
    <>
    <div className=' flex justify-center items-center h-96 w-auto bg-[#0094eb] relative overflow-hidden'>
      <p className=' z-10 flex justify-start uppercase font-bold lg:text-9xl md:text-7xl sm:text-5xl text-6xl ' >
        case study
      </p> 
      <div className=' md:w-[900px] md:h-[900px] sm:w-[450px] sm:h-[450px] w-[350px] h-[350px] rounded-full flex items-center justify-center bg-[#05a3ff]  absolute '>
      <div className=' md:w-[700px] md:h-[700px] sm:w-[350px] sm:h-[350px] w-[250px] h-[250px] rounded-full flex items-center justify-center bg-[#1fadff]  absolute '>
      <div className=' md:w-[500px] md:h-[500px] sm:w-[250px] sm:h-[250px] w-[170px] h-[170px] rounded-full flex items-center justify-center bg-[#38b6ff]  absolute '>
      <div className=' md:w-[300px] md:h-[300px] sm:w-[150px] sm:h-[150px] w-[100px] h-[100px] rounded-full flex items-center justify-center bg-[#52bfff]  absolute '>

       </div>
       </div>
       </div>
      </div>
    </div>

    </>
  )
}

export default CaseStudyHead
