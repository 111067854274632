import React from 'react'
import dev1 from '../../../../assets/services/svg4.json'
import dev7 from '../../../../assets/services/svg7.json'
// import dev3 from '../../../../assets/services/4827600_prev_ui.png'
import Lottie from 'lottie-react'
import responsive from '../../../../assets/services/responsive.json'
import './style.css';


function UiUxContentEnd() {
  return (
     <div className='sm:mb-60'>
    <div className=' flex justify-center items-center rp '>
       
        <div className='grid grid-cols-1 lg:grid-cols-3  gap-y-11 sm:gap-x-11  gap-x-11 ' >

        <div className='rounded-lg  cardcol '>
            <div className='flex justify-center items-center'>
              <Lottie className=' w-28 p-1 ' animationData={responsive}/>
            </div>
            
            <h2 className='text-center text-[#18B7F6]  text-xl text-i '> Responsive Design</h2>
            <h3 className='text-center text-sm  text-Whitefooter'>In today's multi-device world, a responsive design is crucial. We ensure that your digital product looks and functions flawlessly across various screen sizes and devices, providing a consistent experience to all users.</h3>
          
          </div>
          <div className='rounded-lg cardcol '>
            <div className='flex justify-center items-center'>
              <Lottie className=' w-28 p-3' animationData={dev7}/>
            </div>
            
            <h2 className='text-center text-[#18B7F6]  text-xl text-i '>Micro interactions and Animations</h2>
            <h3 className='text-center text-sm  text-Whitefooter'>Small details can make a big difference. Our designers strategically implement micro interactions and animations to add delightful moments and enhance overall usability.</h3>
          
          </div>
          <div className='rounded-lg cardcol '>
            <div className='flex justify-center items-center'>
              <Lottie animationData={dev1} className='w-28 p-2'/>
            </div>
            
            <h2 className='text-center text-[#18B7F6]  text-xl text-i '>Usability Testing</h2>
            <h3 className='text-center text-sm  text-Whitefooter'>Rigorous usability testing is a fundamental part of our design process. We gather feedback from real users to identify areas for improvement and make data-driven design decisions.</h3>
          
          </div>
          
          

        </div>
      
    </div>
    </div>
  )
}

export default UiUxContentEnd
