import Lottie from 'lottie-react'
import React from 'react'
import bg from '../blockchain/Images/blockchainfuture.json'

function BlockchainHeader() {
  return (
    <div className=' rp mt-16 mb-32'>
      <p className=' text-center sm:text-4xl text-2xl font-bold text-LightSky uppercase'>Blockchain<span className=' text-DarkGray'> Solutions for the Future</span></p>
      <div className=' flex flex-col  sm:flex-row  justify-center items-center'>
      <div className=' sm:mb-0 mb-10'>
        <p className=' sm:text-xl text-lg mt-4 text-[#a6a6a6]'>
        Welcome to the Blockchain section of<span className=' font-bold text-LightSky'> Perceptions Technologies. </span>
        </p>
        <p className=' sm:text-lg text-base sm:mt-5 mt-2'>
        We are your gateway to a decentralized and secure digital future. 
        With blockchain technology, we are not just changing the game, we are rewriting the rules.</p>
        
      </div>
          <div className=''>
          <Lottie animationData={bg} className=' w-[80vw] sm:w-full'/>
          </div>
      </div>
    </div>
  )
}

export default BlockchainHeader
