import React from 'react'
import uiuxteam from '../../../../assets/services/uiuxteam.webp'
import { Link } from 'react-router-dom'


function UiUxParaTwo() {
  const ScrollTop = () => {
    window.scrollTo(0,0);
  }

  

  return (
    
    <div className='rp'>   
     <div className='  flex justify-center items-center'>
       
      <div className='grid grid-cols-1 md:grid-cols-2  sm:mb-24 mb-10' >
          <div className='flex justify-center items-center'>
            <img src={uiuxteam} alt="team" />
          </div>
          <div className='flex flex-col justify-center items-center p-5 md:p-14'>
            <h2 >With a talented team of UI/UX designers who are passionate about creating delightful experiences, Perception Technologies is your partner in shaping digital products that resonate with your audience and elevate your brand to new heights.</h2>
            <h1 className='mt-2'>Let's collaborate to design experiences that leave a lasting impression.</h1>
              <h1 className='mt-2'> Contact us now to embark on your UI/UX Design journey with Perceptions Technolgies!</h1>
                      
          </div>
          
      </div>
    </div>
    {/* <div className=' rp flex justify-center items-center gap-8'>
         {pict.map((image, index)=>(
          <img src={image} key={index} alt="gdeejhg" className='sm:w-20 w-12' />
          ))}
    </div> */}
      <div className='sm:flex justify-between mb-10'>

      <Link to='/Web'>  <button onClick={ScrollTop} className=" mb-10  bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition-all duration-500 ease-in-out hover:scale-110 hover:brightness-110 hover:animate-pulse active:animate-bounce">
  Previous Service
</button></Link>

      <Link to='/Digital'>  <button onClick={ScrollTop} className="  bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition-all duration-500 ease-in-out hover:scale-110 hover:brightness-110 hover:animate-pulse active:animate-bounce">
  Next Service
</button></Link>
</div>
    </div>

  )
}

export default UiUxParaTwo
