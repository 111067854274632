import React from 'react'
import WebHero from '../components/web/WebHero'
import WebServicefinal from '../components/web/WebServicefinal'
import WebServicedivs from '../components/web/WebServicedivs'
import WebDevEnd from '../components/web/WebDevEnd'
import { Helmet } from 'react-helmet'

function Web() {
  return (
    <div>
       <Helmet>
      <title>
        Web Development - Perceptions Technologies
      </title>
    </Helmet>
      <WebHero/>
      <WebServicefinal/>
      <WebServicedivs/>
      <WebDevEnd/>
    </div>
  )
}

export default Web
