import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
// Perceptions Routes
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import PreLoader from './components/layout/PreLoader';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Services from './components/pages/Services';
import AppDev from './components/services/pages/AppDev';
import Web from './components/services/pages/Web';
import Blockchain from './components/services/pages/Blockchain';
import AnalyticsBots from './components/services/pages/AnalyticsBots';
import Digital from './components/services/pages/Digital';
import UiUx from './components/services/pages/UiUx';
import ContactUs from './components/pages/ContactUs';
import PrivacyPolicy from './components/layout/PrivacyPolicy';
import Teams from './components/pages/Team';
import CaseStudy from './components/casestudy/CaseStudy';
import CaseStudyPage from './components/casestudy/CaseStudyPage';

// Insightsnest Routes
import HomeNest from './insightsnestComponents/Pages/Home/Home';
import NavBarNest from './insightsnestComponents/Common/NavBar';
import FooterNest from './insightsnestComponents/Common/Footer';
import ContactNest from './insightsnestComponents/Forms/ContactUs';
import ServiceFormNest from './insightsnestComponents/Pages/ServicesForm';
import ServicesNest from './insightsnestComponents/Pages/Home/Services';
import ScrollToTopNest from './insightsnestComponents/Common/ScrollToTop';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <BrowserRouter>
      <Helmet>
        <title>Perceptions Technologies</title>
      </Helmet>
      <Routes>
        <Route path='/*' element={<PerceptionsRoutes />} />
        <Route path='/insightsnest/*' element={<InsightsnestRoutes />} />
      </Routes>
    </BrowserRouter>
  );
}

const PerceptionsRoutes = () => (
  <>
    <Navbar />
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/About' element={<About />} />
      <Route path='/Services' element={<Services />} />
      <Route path="*" element={<Navigate to="/" replace={true} />} />
      <Route path='/AppDev' element={<AppDev />} />
      <Route path='/Blockchain' element={<Blockchain />} />
      <Route path='/AnalyticsBots' element={<AnalyticsBots />} />
      <Route path='/Digital' element={<Digital />} />
      <Route path='/UiUx' element={<UiUx />} />
      <Route path='/Web' element={<Web />} />
      <Route path='/contact-us' element={<ContactUs />} />
      <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
      <Route path='/teams' element={<Teams />} />
      <Route path='/case-study' element={<CaseStudy />} />
      <Route path='/case-study-page/:id' element={<CaseStudyPage />} />
    </Routes>
    <Footer />
  </>
);

const InsightsnestRoutes = () => (
  <>
  <div className=' bg-white text-black nestfont m-0 p-0'>

    <NavBarNest />
    <ScrollToTopNest />
    <Routes>
      <Route path='/' element={<HomeNest />} />
      <Route path='/contactus' element={<ContactNest />} />
      <Route path='/services' element={<ServicesNest />} />
      <Route path='/service/:id' element={<ServiceFormNest />} />
      <Route path="*" element={<Navigate to="/insightsnest" replace={true} />} />
    </Routes>
    <FooterNest />
  </div>
  </>
);

export default App;
