import React from 'react'
import Analytics from '../../../../assets/services/analytics.json'
import Lottie from 'lottie-react'

function Analyticshero() {
  return (
    <div className='rp font-Poppins break-words sm:mb-32 mb-10'>



    <div className='sm:flex sm:flex-row-reverse justify-between items-center mb-10'>
      <div className=' flex flex-col gap-y-10'>

      <h2 className='lg:text-6xl md:text-3xl sm:text-left text-center text-2xl'>Powering Your <span className='from-LightSky via-blue-400 to-Summer bg-gradient-to-r bg-clip-text text-transparent'>Digital</span> Future</h2>
      <p className='lg:text-lg md:text-base'>This is where data meets automation to supercharge your business operations. Let's dive into the magic of analytics and the efficiency of bots.</p>
    
      </div>
<div>
    
    <Lottie className=' sm:w-[50%] w-[80%] mx-auto' animationData={Analytics} />
</div>


    </div>
  
      <h2 className='text-2xl text-center mb-12'> Unleashing the Potential of <span className='font-bold underline text-Summer underline-offset-4'>Data</span></h2>
      <p className=' text-lg text-center'>In today's digital age, data is king. But it's not just about having data; it's about what you do with it. Our analytics solutions are designed to turn raw data into actionable insights.</p>
    

  </div>
  )
}

export default Analyticshero
