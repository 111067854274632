import React from 'react'
import bgpng from '../blockchain/Images/blockchain.png'

const Whatisblockchain = () => {
  return (
    <div className=' rp sm:flex justify-center items-center mb-16 bg-gradient-to-r from-LightSky to-[#000]'>
      <div className=' basis-[40%] hidden sm:block  '>
        <img src={bgpng} alt="" className=" p-9 w-[90%] " />
      </div>
      <div className='  basis-[40%] sm:mb-0 mb-10 '>
        <p className='  sm:text-5xl text-2xl uppercase font-bold '>
            what is <span className=' text-LightSky drop-shadow-lg'>blockchain?</span> 
        </p>
        <p className=' mt-4 sm:text-lg text-base text-Gainsboro'>
        Blockchain is more than just a buzzword. It is a revolutionary technology that is 
        transforming industries. At its core, blockchain is a distributed and immutable ledger 
        that ensures transparency, security, and trust in digital transactions. Think of it as 
        the digital version of an unbreakable chain.
        </p>

      </div>
      <div className=' basis-[40%] block sm:hidden  '>
        <img src={bgpng} alt="" className=" p-9 w-[90%] " />
      </div>
    </div>
  )
}

export default Whatisblockchain
