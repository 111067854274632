import React from 'react'
import './styleapp.css';

function AppdevContent() {
  return (
    <div className=' p-5 top-40 flex justify-center items-center  sm:mb-32 m-10'>
        <div className=' grid  max-[1060px]:grid-cols-2 max-[540px]:grid-cols-1 grid-cols-4 gap-x-11 gap-y-11 '>
            <div className=''>
                <div className="cardbox">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <div>
                    <svg className="check" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                    
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path>
                    </svg>
                    <strong className=''>Custom Application Development</strong>
                    <p className='  text-white pt-7'>Our team of expert developers thrives on crafting tailor-made solutions to address your unique business challenges. Whether you need a web application, mobile app, or desktop software, we have the expertise to deliver the perfect fit. </p>
                    
                    
                </div>
                </div>
            </div>
        
            <div  className=''>
              <div className="cardbox">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <div>
                  <svg className="check" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                    
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path>
                  </svg>
                  <strong >Mobile App Development</strong>
                  <p className=' text-white pt-7'>Tap into the vast potential of the mobile world with our top-notch mobile app development services. From Android to iOS, we build user-friendly, feature-rich mobile applications that captivate users and drive results. </p>
                  
                  
                </div>
              </div> 
            </div>

            <div >
            <div className="cardbox">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <div>
                <svg className="check" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                  
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path>
                </svg>
                <strong>Web Application Development: </strong>
                <p className=' text-white pt-7'>Embrace the power of web-based applications to enhance your online presence and streamline your operations. Our web applications are highly responsive, secure, and optimized for peak performance. </p>
                
              
              </div>
            </div>
            </div>
            <div  className=''>
            <div className="cardbox">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <div>
                <svg className="check" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                  
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path>
                </svg>
                <strong >Enterprise Application Solutions</strong>
                <p className='text-white pt-7'>Streamline your business processes and boost productivity with our cutting-edge enterprise applications. We create scalable, integrated solutions that foster collaboration and drive efficient workflows. </p>
                
                
              </div>
            </div>
            </div>






            </div>
            
      
    </div>
  )
}

export default AppdevContent
