import React from 'react'
import { Helmet } from 'react-helmet'

function PrivacyPolicy() {
  return (
    <div className='     bg-[#B4B4B3]  text-black
 
    
    
    '>
       <Helmet>
      <title>
        Terms and Conditions Policy - Perceptions Technologies
      </title>
    </Helmet>
      <h1 className='rp text-lg mt-7 mb-10 text-center font-bold uppercase bg-Topaz text-white py-5 shadow-sm shadow-gray-300'>Terms and Conditions of <span className=' font-bold uppercase'>Perceptions technologies</span></h1>
    <div className='rp mt-7'>
      <p className='text-lg mb-3'>The Terms and Conditions of Perceptions Technologies is effective date:-  21/09/2023 </p>
      <p className='text-lg mb-3'>Here our, us, we refer to Perceptions Technologies. You, yours refer to customer. </p>
     
     <p className='mb-10'>Welcome to Perceptions Technologies website, at our website we value the trust you place in us when you visit our website. At Perception Technologies we are committed to protecting the privacy and security of your personal information. Our privacy policy explain how we collect and use your personal data when you visit our site. We make utmost care to protect your personal data. </p>


<h2 className='text-xl underline underline-offset-4 mb-5'>What information we collect  </h2>

<p className='mb-10'>We collect personal information that you voluntarily provide to us at time of signing to our web site like name, email address, phone number, and other information you provide  us while using our website or communicating with us. Some information are automatically collected by us like IP address, browser type, device information and browsing behaviour. </p>



<h2 className='text-xl underline underline-offset-4 mb-5'>How we use your personal information </h2>
    <p className='mb-5'>Following are the purpose for which we use your personal data: </p>

<ol className=' list-decimal ml-5 mb-10'>
  <li className='mb-3'>Provide effective service and improve efficiency of our service. </li>
  <li className='mb-3'>To respond to your inquiry, request and complains. </li>
  <li className='mb-3'>To send marketing and promotional material which may be useful to you with your consent.</li>
  <li className='mb-3'>To monitor and analysis usage patterns and trends we use your personal data. </li>
  <li className='mb-3'>To offer job opportunity in case of vacancy. </li>
</ol>
    
<h2 className='text-xl underline underline-offset-4 mb-5'>

  
Cookies and other identifiers 
</h2>

<p className='mb-10'>
  
  We use cookies and other identifiers to collect information about your browsing activity on perception technologies. If you want you can control cookies through your browser settings. 
  </p>

<h2 className='text-xl underline underline-offset-4 mb-5'>

  
  How we share your information  
 
</h2>
 


<p className='mb-10'>
  
  We may share your personal information to our affiliates and service provider for the purpose described in private policy and law enforcement or government agencies when required by the law. 
  </p>

 

 
<h2 className='text-xl underline underline-offset-4 mb-5'>


Security of your personal data 
</h2>


<p className='mb-10'>
  
  We take responsible measure to protect the security and confidentiality of your personal information we use variety of security technologies and procedures to help to protect your data from unauthorized use, access, or discloser. 

But your responsibility if username and/or password is used to protect your account , then its your responsibility to keep your information confidential. Do not share your information with anyone. If you sharing your internet or computer you should logout before sharing. 
  </p>

 

 
<h2 className='text-xl underline underline-offset-4 mb-5'>


Your choices 
</h2>



You have following choice regarding your personal information:
<ol className=' list-decimal ml-5 mb-10 mt-5'>
  <li className='mb-3'>
  You can opt-out of receiving marketing communication from us. 
  </li>
<li className='mb-10'>
you can request access to, correction of or deletion of your personal information. 
</li>
</ol>

 

<h2 className='text-xl underline underline-offset-4 mb-5'>

Link to third party websites 
</h2>


<p className='mb-10'>
  
  Oue website may contains links to third party websites. We are not responsible for the privacy practice or contents of these websites. Be careful before providing information. 
  </p>

 

<h2 className='text-xl underline underline-offset-4 mb-5'>


Changes to our privacy policy 
</h2>

<p className='mb-10'>
  
  We may need to change the privacy policy in order to comply with or adapt to legal, technics or business development. Most current version will be posted. 
  </p>
<h2 className='text-xl underline underline-offset-4 mb-5'>


Contact us 
</h2>

<p className='mb-5'>

If you have any questions or concern about this privacy policy or your personal information 
</p>

<p className='mb-24 pb-5'>
Please contact us at :- services@perceptionstechnologies.com
  
</p>


    
    



    
    
    
    
    
    
    </div>
    </div>


  )
}

export default PrivacyPolicy
