import React from 'react'
import Lottie from 'lottie-react'
import Chat from '../../../../assets/services/chatbot.json'

function ChatBotHero() {
  return (
    <div className='rp font-Poppins break-words sm:mb-32 mb-16'>



    <div className='sm:flex justify-between items-center md:pt-0 sm:pt-5 sm:mb-20 mb-10'>

      <div className='basis-1/2 flex flex-col gap-y-10 sm:mb-0 mb-5'>

      <h2 className='lg:text-6xl md:text-3xl sm:text-left text-center text-2xl'>Your <span className='from-LightSky via-blue-400 to-Summer bg-gradient-to-r bg-clip-text text-transparent'>Digital</span> Workforce</h2>
      <p className='lg:text-lg md:text-base'>Imagine having a tireless, 24/7 digital assistant that never complains about overtime. That's the power of bots.</p>
      <span className='dashed-line'></span>
      </div>
<div>
    
    <Lottie className='sm:basis-[45%]' animationData={Chat} />
</div>

    </div>

    <p className=' text-lg'>At <span className='font-bold text-Summer'>Perceptions Technologies</span>, we believe in harnessing the power of Artificial Intelligence (AI) to revolutionize the way businesses engage with their customers. Our Chat Bot services offer smart, efficient, and personalized solutions to enhance customer interactions, streamline operations, and boost overall productivity.</p>

  </div>
  )
}

export default ChatBotHero
