import React from 'react'
import Hero from '../home/Hero'
import AboutSection from '../home/AboutSection'
import ServiceCard from '../home/ServiceCard'
import LogoSlider from '../home/LogoSlider'
import { Helmet } from 'react-helmet'
import Testimonial from '../home/Testimonial'
// import Signup from '../home/Signup'
// import Login from '../home/Login'


function Home() {
  return (
    <>
    <Helmet>
      <title>
        Perceptions Technologies
      </title>
    </Helmet>
    <div>
      <Hero/>
      <AboutSection/>
      <ServiceCard/>
      <LogoSlider/>
      <Testimonial/>
      {/* <Signup/> */}
      {/* <Login/> */}
    </div>
    </>
  )
}

export default Home
