import Lottie from 'lottie-react'
import React from 'react'
import svg2 from '../../../../assets/services/appdev123.json'
import { Link } from 'react-router-dom'
// import reactnative from '../../../../assets/home/slider/react-native.png'
// import java from '../../../../assets/home/slider/java-original.svg'
// import fluter from '../../../../assets/home/slider/Flutter.png'



function AppdevPara() {

  const ScrollTop = () => {
    window.scrollTo(0,0);
  }
  return (
    <div className='rp'>
        <div className=' grid grid-cols-1 md:grid-cols-2 mb-16 '>
            <div className='flex justify-center flex-col p-1 xl:p-40 order-2 md:order-1'>
            <h1 className=' text-justify'>With a team of passionate developers and a commitment to excellence, Perceptions Technologies is your reliable partner in creating powerful applications that drive success and innovation.</h1>
            <h1 className=' text-justify'>Let's collaborate to build applications that propel your business forward. Contact us now to begin your Application Development journey with Perceptions Technologies!</h1>
            </div>
            <div className='order-1 md:order-2'>
                <Lottie animationData={svg2} className=''/>
            </div>

        </div>

        {/* <div className=' rp flex justify-center items-center gap-20'>
          <img src={reactnative} alt=""  className=' sm:w-20 w-12'/>

          <img src={java} alt=""  className=' sm:w-20 w-12'/>

          <img src={fluter} alt="" className=' sm:w-20 w-12' />
        </div> */}
 
   <div className='flex justify-end'>

      <Link to='/Web'>  <button onClick={ScrollTop} className=" bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition-all duration-500 ease-in-out hover:scale-110 hover:brightness-110 hover:animate-pulse active:animate-bounce">
  Next Service
</button></Link>
</div>
    </div>
  )
}

export default AppdevPara
