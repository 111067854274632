import React, { useState } from 'react';
import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';
import Logo from '../../../../assets/logo/perceptions.png';

function ChatBotCards() {
  const [inputValue1, setInputValue1] = useState('');
  const [inputValue2, setInputValue2] = useState('');
  const [inputValue3, setInputValue3] = useState('');
  const [inputValue4, setInputValue4] = useState('');

  const handleSendClick = (inputNumber) => {
    // Handle the logic for sending the message here
    // For now, let's just clear the corresponding input field
    if (inputNumber === 1) {
      setInputValue1('');
    } else if (inputNumber === 2) {
      setInputValue2('');
    } else if (inputNumber === 3) {
      setInputValue3('');
    } else if (inputNumber === 4) {
      setInputValue4('');
    }
  };

  return (
    <div className='rp font-Poppins break-words sm:mb-32 mb-16'>
      <div className='sm:flex justify-center max-[900px]:gap-x-10 gap-x-20  mb-20 max-[900px]:mb-10'>
        {/* Card 1 */}
        <div className='justify-between flex flex-col basis-1/4 bg-white rounded-lg text-black sm:mb-0 mb-10'>
          <div className='text-center bg-blue-400 text-white font-bold rounded-lg p-2 overflow-hidden shadow-lg'>Perceptions Bot</div>
          <div className='flex flex-col gap-y-5 p-2'>
            <div className='flex items-end gap-x-1'>
              <img className='w-6 h-6' src={Logo} alt="perceptions logo" />
              <h2 className='bg-gray-200 p-2 rounded-t-lg rounded-r-lg'>Process Automation</h2>
            </div>
            <div className='flex justify-between items-end gap-x-1'>
              <p className='bg-gray-200 p-2 rounded-t-lg rounded-r-lg'>Say goodbye to repetitive tasks. Bots can automate workflows, reducing manual labor and human error.</p>
              <IoCheckmarkDoneCircleOutline className='w-32' />
            </div>
            <div className='flex justify-between'>
              <input
                className='bg-gray-200 w-2/3 p-1 rounded-lg text-sm'
                type="text"
                name='Message'
                placeholder='Enter your text'
                value={inputValue1}
                onChange={(e) => setInputValue1(e.target.value)}
              />
              <button
                className='bg-blue-500 text-white px-2 rounded-xl'
                onClick={() => handleSendClick(1)}
              >
                Send
              </button>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className='justify-between flex flex-col basis-1/4 bg-white rounded-lg text-black'>
          <div className='text-center bg-blue-400 text-white font-bold rounded-lg p-2 overflow-hidden shadow-lg'>Perceptions Bot</div>
          <div className='flex flex-col gap-y-5 p-2 '>
            <div className='flex items-end gap-x-1'>
              <img className='w-6 h-6' src={Logo} alt="perceptions logo" />
              <h2 className='bg-gray-200 p-2 rounded-t-lg rounded-r-lg'>Data Gathering</h2>
            </div>
            <div className='flex items-end gap-x-1'>
              <p className='bg-gray-200 p-2 rounded-t-lg rounded-r-lg'>Need to collect data from multiple sources? Bots can do it faster and more accurately than any human.</p>
              <IoCheckmarkDoneCircleOutline className='w-32' />
            </div>
            <div className='flex justify-between'>
              <input
                 className='bg-gray-200 w-2/3 p-1 rounded-lg text-sm'
                type="text"
                name='Message'
                placeholder='Enter your text'
                value={inputValue2}
                onChange={(e) => setInputValue2(e.target.value)}
              />
              <button
                className='bg-blue-500 text-white px-2 rounded-xl'
                onClick={() => handleSendClick(2)}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='sm:flex justify-center max-[900px]:gap-x-10 gap-x-20 mb-20'>
        {/* Card 3 */}
        <div className='justify-between flex flex-col basis-1/4 bg-white rounded-lg text-black sm:mb-0 mb-10'>
          <div className='text-center bg-blue-400 text-white font-bold rounded-lg p-2 overflow-hidden shadow-lg'>Perceptions Bot</div>
          <div className='flex flex-col justify-between gap-y-5 p-2 '>
            <div className='flex items-end gap-x-1'>
              <img className='w-6 h-6' src={Logo} alt="perceptions logo" />
              <h2 className='bg-gray-200 p-2 rounded-t-lg rounded-r-lg'>Expertise</h2>
            </div>
            <div className='flex justify-between items-end gap-x-1'>
              <p className='bg-gray-200 p-2 rounded-t-lg rounded-r-lg'>Our team includes data wizards and bot whisperers who understand the intricate world of analytics and automation.</p>
              <IoCheckmarkDoneCircleOutline className='w-32' />
            </div>
            <div className='flex justify-between'>
              <input
                 className='bg-gray-200 w-2/3 p-1 rounded-lg text-sm'
                type="text"
                name='Message'
                placeholder='Enter your text'
                value={inputValue3}
                onChange={(e) => setInputValue3(e.target.value)}
              />
              <button
                className='bg-blue-500 text-white px-2 rounded-xl'
                onClick={() => handleSendClick(3)}
              >
                Send
              </button>
            </div>
          </div>
        </div>

        {/* Card 4 */}
        <div className='justify-between flex flex-col basis-1/4 bg-white rounded-lg text-black'>
          <div className='text-center bg-blue-400 rounded-lg p-2 overflow-hidden shadow-lg text-white'>Perceptions Bot</div>
          <div className='flex flex-col gap-y-5 p-2 '>
            <div className='flex items-end gap-x-1'>
              <img className='w-6 h-6' src={Logo} alt="perceptions logo" />
              <h2 className='bg-gray-200 p-2 rounded-t-lg rounded-r-lg'>Chatbots</h2>
            </div>
            <div className='flex items-end gap-x-1'>
              <p className='bg-gray-200 p-2 rounded-t-lg rounded-r-lg'>Enhance customer support with AI-driven chatbots that answer questions, solve problems, and assist users round the clock.</p>
              <IoCheckmarkDoneCircleOutline className='w-32' />
            </div>
            <div className='flex justify-between'>
              <input
                  className='bg-gray-200 w-2/3 p-1 rounded-lg text-sm'
                type="text"
                name='Message'
                placeholder='Enter your text'
                value={inputValue4}
                onChange={(e) => setInputValue4(e.target.value)}
              />
              <button
                className='bg-blue-500 text-white px-2 rounded-xl'
                onClick={() => handleSendClick(4)}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatBotCards;
