import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { Squash as Hamburger } from 'hamburger-react';
import logo from '../../assets-insightsnest/NAV-LOGO.png';
import { IoIosArrowDropdown } from "react-icons/io";
const NavBar = () => {

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const location = useLocation();

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 640);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 
  useEffect(() => {
    const handleScroll = () => {
      if (isNavVisible && window.innerWidth <= 640) {
        setIsNavVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isNavVisible]);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const closeNav = () => {
    setIsNavVisible(false);
    setIsCareerGuidanceOpen(false);
    setIsFamilyCounsellingOpen(false);
    window.scrollTo(0, 0);
  };

  const smoothScrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: 'easeInOutQuart',
    });
  };

  const isSamePage = (path) => {
    return location.pathname === path;
  };

  
  const [isCareerGuidanceOpen, setIsCareerGuidanceOpen] = useState(false);
  const [isFamilyCounsellingOpen, setIsFamilyCounsellingOpen] = useState(false);

  return (
    <div className=' flex justify-center pt-10 '>
      <div className={` ${isSmallScreen ? ' absolute z-10 w-[95%] h-[3rem] rounded-full shadow-lg bg-[#FAF9F6] cursor-pointer  ' : 'w-[95%] h-[4rem] rounded-full shadow-2xl bg-[#FAF9F6]  z-10 flex justify-between items-center px-10 absolute '}`}>
        <div className='flex justify-between items-center sm:w-auto'>
          <Link to="/insightsnest" onClick={isSamePage('/insightsnest') ? smoothScrollToTop : closeNav} className='px-3'>
            <img className='sm: w-36' src={logo} alt='logo' />
          </Link>
          {isSmallScreen && (
            <div className="px-3">
              <Hamburger
                toggled={isNavVisible}
                toggle={toggleNav}
                duration={0.5}
                size={22}
                color="#000"
                aria-label="Open Menu"
              />
            </div>
          )}
        </div>

        <ul
          className={`${
            isSmallScreen
              ? (isNavVisible ? ' flex flex-col gap-y-5 absolute bg-[#FAF9F6] rounded-3xl  shadow-lg w-full bg-opacity-95 px-6 py-5 mt-2 ' : 'hidden ')
              : 'flex md:text-base font-bold lg:gap-18 md:gap-12 sm:gap-3 '
          }`}
        >
          {[
            { path: '/insightsnest', label: 'Home' },
            { label: 'Career Guidance', dropdown: [
                { path: '#', label: 'Technical Jobs' },
                { path: '#', label: 'Departament of Atomic Energy' },
                
              ]},
            { label: 'Family Counselling', dropdown: [
                { path: '#', label: 'Swimming with Sharks for a Sucessful life' },
                { path: '#', label: 'Buoy in Family Matter' },
                
              ]},
            { path: '#', label: 'Online Coaching' },
            { path: '#', label: 'About us', marginBottom: isSmallScreen ? 'mb-4' : '' },
          ].map((item) => (
            <li key={item.label}>
              {item.dropdown ? (
                <div className=' flex flex-row items-center px-2 hover:shadow-md sm:bg-[#FAF9F6] bg-[#FAF9F6]/[0.1]  rounded-full'>
                  <span  onClick={() => {
                    if (item.label === 'Career Guidance') {
                      setIsCareerGuidanceOpen(!isCareerGuidanceOpen);
                      setIsFamilyCounsellingOpen(false);
                    } else if (item.label === 'Family Counselling') {
                      setIsFamilyCounsellingOpen(!isFamilyCounsellingOpen);
                      setIsCareerGuidanceOpen(false);
                    }
                  }}>
                    <p className=' cursor-pointer inline-flex items-center gap-2'>

                    {item.label} <IoIosArrowDropdown />
                    </p>
                  </span>
                  {(isCareerGuidanceOpen && item.label === 'Career Guidance') ||
                  (isFamilyCounsellingOpen && item.label === 'Family Counselling') ? (
                    <div className="z-10 absolute sm:mt-36 mt-28 sm:ml-2 ml-24 bg-[#FAF9F6]/[0.7] divide-y divide-gray-100 rounded-lg shadow-xl w-44">
                      <ul className='p-2 text-sm '>
                        {item.dropdown.map((subItem) => (
                          <li className=' mb-2 hover:bg-gray-500/[0.8] px-1 rounded ' key={subItem.label}>
                            <Link
                              className={isSamePage(subItem.path) ? 'active' : ''}
                              to={subItem.path}
                              onClick={isSamePage(subItem.path) ? smoothScrollToTop : closeNav}
                            >
                              {subItem.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </div>
              ) : (
                <Link
                  className={isSamePage(item.path) ? 'active' : 'hover:text-gray-500 ' + item.marginBottom}
                  to={item.path}
                  onClick={isSamePage(item.path) ? smoothScrollToTop : closeNav}
                >
                  {item.label}
                </Link>
              )}
            </li>
          ))}
        </ul>
        <div className={`${
          isSmallScreen
            ? (isNavVisible ? '' : 'hidden')
            : ' uppercase'
        }`}>
          <Link to='/insightsnest/contactus'>
            <button onClick={closeNav} className='sm:visible max-sm:hidden relative sm:text-base sm:px-3 px-0 py-2 sm:rounded-3xl overflow-hidden group flex flex-row justify-center items-center gap-1'>
              <span className='absolute left-0 top-0 w-0 h-full bg-Summer transition-all duration-300 group-hover:w-full z-0 '></span>
              {/* < IoIosCall className=' font-bold '/> */}
              <span className="relative z-10 font-semibold ">Contact Us</span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NavBar;
