import React from 'react'
import UiUxHeader from '../components/uiux/UiUxHeader'
import UiUxIntro from '../components/uiux/UiUxIntro'

import UiUxContentStart from '../components/uiux/UiUxContentStart'
import UiUxContentMiddle from '../components/uiux/UiUxContentMiddle'
import UiUxContentEnd from '../components/uiux/UiUxContentEnd'
import UiUxParaOne from '../components/uiux/UiUxParaOne'
import UiUxParaTwo from '../components/uiux/UiUxParaTwo'
import { Helmet } from 'react-helmet'
function UiUx() {
  return (
    <div>
       <Helmet>
      <title>
        UiUx - Perceptions Technologies
      </title>
    </Helmet>
      <UiUxHeader/>
      <UiUxIntro/>
      
      <UiUxContentStart/>
      <UiUxContentMiddle/>
      <UiUxContentEnd/>
      <UiUxParaOne/>
      <UiUxParaTwo/>
     
    </div>
  )
}

export default UiUx
