import Lottie from 'lottie-react'
import React from 'react'
import appdev from '../../../../assets/services/appdev.json'

function appdevheader() {
  return (
    <div className='   rp mb-20'>
      <div className='grid grid-cols-1 md:grid-cols-2  rounded-lg '>
      <div className=''>
          <Lottie animationData={appdev} className=' -top-16'/>
        </div>
        <div className=' md:p-11 flex justify-center   flex-col'>
            <h1 className='text-3xl font-bold text-Summer font-Inter p-1 md:p-2  text-left'>Application Development</h1>
            <h1 className='p-2'>At Perceptions Technologies, we specialize in building robust, scalable, and innovative applications that drive business growth and exceed user expectations. With a focus on cutting-edge technologies and agile development methodologies, our Application Development services are designed to cater to a diverse range of industries and business needs.
            </h1>
        </div>
        
        
      </div>
      
    </div>
  )
}

export default appdevheader
