import React from 'react'
import Hero from '../about/Hero'
import Who from '../about/Whoweare'
import Approach from '../about/Approach'
import Whatwe from '../about/Whatwe'
import SubSection from '../about/SubSection'
import Whyus from '../about/Whyus'
import { Helmet } from 'react-helmet'

function About() {
  return (
    <div>
       <Helmet>
      <title>
        About - Perceptions Technologies
      </title>
    </Helmet>
     <Hero/>
     <Who/>
     <Approach/>
     <Whatwe/>
     <SubSection/>
     <Whyus/>
    </div>
  )
}

export default About
