import React from 'react'

const WhyusBlockchain = () => {
  return (
    <div className='rp flex flex-col justify-center items-center mb-28'>
      <p className=' sm:text-5xl text-2xl font-bold text-LightSky'>
      Why Choose Us?
      </p>
      <p className=' sm:mt-4 sm:text-2xl text-lg mt-3 text-DarkGray'>
      Blockchain is more than just technology.
      </p>
      <p className=' sm:mt-4 mt-3 sm:text-lg text-base'>
      It is a mindset. We are not just blockchain developers.
      </p>
      <p className=' sm:mt-4 mt-3 sm:text-lg text-base'>
      We are blockchain evangelists. Here is why we are the right choice:
      </p>
    </div>
  )
}

export default WhyusBlockchain
