import React from 'react'
import Responsive from '../../../../assets/services/responsivedesign.png'
import { Link } from 'react-router-dom'

function WebDevEnd() {
  const ScrollTop = () => {
    window.scrollTo(0,0);

  }
  const pict = [];
  return (
    <div className='rp '>
        <div className='mb-10'>
            <div className='grid grid-cols-1 md:grid-cols-3 '>
                <div className='flex flex-col justify-center items-center sm:mb-0 mb-10'>
                    <h1 className=' text-Summer font-bold text-xl sm:mb-0 mb-10 text-left'> Quality Assurance and Testing</h1>
                    <p className='md:text-base max-md:text-sm text-Whitefooter p-5 text-justify'>
        Our meticulous testing processes guarantee that your web applications and software are bug-free, secure, and perform optimally, providing your users with a flawless experience.
          </p>

                </div>
                <div className='sm:mb-0 mb-10'>
                <img src={Responsive} alt="Responsive Website Design png" />

                </div>
                <div className='flex flex-col justify-center items-center'>
                    <h1 className='text-Summer font-bold sm:mb-0 mb-10 text-left text-xl'>Software Integration Services</h1>
                    <p className='md:text-base max-md:text-sm text-Whitefooter p-5 text-justify'>
                    Seamlessly connect your software applications and systems with our integration services. We ensure that your software ecosystem works harmoniously, improving overall efficiency and productivity
                    </p>

                </div>

            </div>
        </div>

        <div className= ' rp flex justify-center items-center gap-7 mb-10 '>

          {pict.map((image, index)=>(
          <img src={image} key={index} alt="gdeejhg" className='sm:w-20 w-12' />
          ))}
           
          


          {/* <img src={react} alt="" />
          <img src={bootstrap} alt="" />
          <img src={css} alt="" />
          <img src={django} alt="" />
          <img src={express} alt="" />
          <img src={html} alt="" />
          <img src={javascript} alt="" />
          <img src={mongodb} alt="" />
          <img src={nextjs} alt="" />
          <img src={nextjss} alt="" />
           <img src={node} alt="" />
          <img src={tailwind} alt="" />
          <img src={three} alt="" />
          <img src={wordpress} alt="" />  */}
        </div>

        <div className='sm:flex justify-between mb-10'>

      <Link to='/AppDev'>  <button onClick={ScrollTop} className=" mb-10 bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition-all duration-500 ease-in-out hover:scale-110 hover:brightness-110 hover:animate-pulse active:animate-bounce">
  Previous Service
</button></Link>

      <Link to='/UiUx'>  <button onClick={ScrollTop} className="  bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition-all duration-500 ease-in-out hover:scale-110 hover:brightness-110 hover:animate-pulse active:animate-bounce">
  Next Service
</button></Link>
</div>
    </div>
  )
}

export default WebDevEnd
