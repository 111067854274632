import React from 'react'
import { Link } from 'react-router-dom'

const CaseStudyitems = ({case:{
  title,
  description,
  Challenges,
   challenge_heading_1,
    challenge_desc_1,
   challenge_heading_2,
   challenge_desc_2,
   challenge_heading_3,
    challenge_desc_3,
   challenge_heading_4,
   challenge_desc_4,
   challenge_heading_5,
   challenge_desc_5,
  solution,
   solution_heading_1,
    solution_desc_1,
   solution_heading_2,
   solution_desc_2,
   solution_heading_3,
   solution_desc_3,
   solution_heading_4,
   solution_desc_4,
   solution_heading_5,
   solution_desc_5,
  objective,
   objective_heading_1,
    objective_desc_1,
   objective_heading_2,
    objective_desc_2,
   objective_heading_3,
    objective_desc_3,
   objective_heading_4,
    objective_desc_4,
   objective_heading_5,
    objective_desc_5,
  result,
   result_heading_1,
    result_desc_1,
   result_heading_2,
   result_desc_2,
   result_heading_3,
   result_desc_3,
   result_heading_4,
    result_desc_4,
   result_heading_5,
   result_desc_5,
   conclusion,
   conclusion_desc,
  cover,
  id,}
  }) => {
  return (
    
    <div className='flex flex-col mb-16 mt-16'>
    <img className='w-full h-56 object-cover rounded-lg mb-2' src={cover} alt='cover' />
    <p className='mb-4 text-2xl font-bold flex-1 '>{title}</p>
    <p className='relative overflow-hidden max-h-20 text-lg text-gray-400'>
      {description}
      <span className='absolute bottom-0 right-0'>...</span>
    </p>
   
    <footer className='flex items-center justify-end mt-4'>
      <Link to={`/case-study-page/${id}`} className='text-[#0094eb] font-bold text-sm hover:underline'>Read More ➝</Link>
    </footer>
  </div>
  
  )
}

export default CaseStudyitems
