import React from 'react'
import plan from '../about/images/plan.png'
import design from '../about/images/design.png'
import define from '../about/images/define.png'
import build from '../about/images/build.png'
import test from '../about/images/testing.png'
import deploy from '../about/images/deploy.png'

const SubSection = () => {
  return (
    <div className=' rp flex flex-col sm:flex-row items-center gap-12 mt-10 justify-center bg-gradient-to-t from-[#475c6e] to-[#000]'>
          <div>
          <img src={plan} alt="" className=" w-28 p-3" />
          <h1 className=' text-center uppercase text-base text-[#a6a6a6] font-bold p-1'>plan</h1>
        </div>
        <div>
        <img src={design} alt="" className=" w-28 p-3" />
          <h1 className=' text-center uppercase text-base text-[#a6a6a6] font-bold p-1'>design</h1>
        </div>
        <div>
        <img src={define} alt="" className=" w-28 p-3" />
          <h1 className=' text-center uppercase text-base text-[#a6a6a6] font-bold p-1'>define</h1>
        </div>
        <div>
        <img src={build} alt="" className=" w-28 p-3" />
          <h1 className=' text-center uppercase text-base text-[#a6a6a6] font-bold p-1'>build</h1>
        </div>
        <div>
        <img src={test} alt="" className=" w-28 p-3" />
          <h1 className=' text-center uppercase text-base text-[#a6a6a6] font-bold p-1'>test</h1>
        </div>
        <div>
        <img src={deploy} alt="" className=" w-28 p-3" />
          <h1 className=' text-center uppercase text-base text-[#a6a6a6] font-bold p-1'>deploy</h1>
        </div>
    </div>
  )
}

export default SubSection
