import React from 'react'

function ExtraServices() {
  return (
    <div className='rp mb-16'>
      <h1 className='text-xl mb-10'>We provide all the necessary services you will need from kicking off your business to a detailed anlysis of your buisness.</h1>
   
   <div className=" mx-auto px-4 py-8 ">
 <div className="grid grid-cols-2  max-[540px]:grid-cols-1 gap-6">
   
   <div className="bg-gradient-to-r from-blue-500 to-blue-600  rounded-lg p-6 shadow-lg text-white">
     <h2 className="text-xl font-semibold mb-2">E-Commerce Solutions</h2>
     <p className="text-gray-200">Maximize your online business potential with our cutting-edge e-commerce solutions.</p>
   </div>

   
   <div className="bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg p-6 shadow-lg text-white">
     <h2 className="text-xl font-semibold mb-2">Business Intelligence</h2>
     <p className="text-gray-200">Get a real-time snapshot of your business's performance.</p>
   </div>

   
   <div className="bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg p-6 shadow-lg text-white">
     <h2 className="text-xl font-semibold mb-2">Usability Testing</h2>
     <p className="text-gray-200">Rigorous usability testing is a fundamental part of our design process.</p>
   </div>

   
   <div className="bg-gradient-to-r from-blue-500 to-blue-600  rounded-lg p-6 shadow-lg text-white">
     <h2 className="text-xl font-semibold mb-2">Custom Solutions</h2>
     <p className="text-gray-200">We tailor analytics and bot solutions to meet your unique business needs.</p>
   </div>
 </div>
</div>






    </div>
  )
}

export default ExtraServices
