import React from 'react'
import Lottie from 'lottie-react'
import Web from '../../../../assets/services/web.json'

function Digital() {
  return (
    <div className='rp font-Poppins break-words sm:mb-24 mb-20'>



      <div className='sm:flex justify-between items-center md:pt-0 sm:pt-5 pt-10'>

        <div className='basis-1/2 flex flex-col gap-y-3'>
          <h1 className='md:text-5xl text-2xl mb-2 text-center uppercase font-bold from-LightSky via-blue-400 to-Summer bg-gradient-to-r bg-clip-text text-transparent'>Web Development</h1>
        <h1 className='sm:text-3xl text-2xl mb-2 text-center'>Establish your digital identity </h1>
<h2 className='sm:text-3xl text-center text-2xl font-bold from-LightSky via-blue-400 to-Summer bg-gradient-to-r bg-clip-text text-transparent uppercase'>" with us"</h2>
<div>

</div>
<p className=' max-lg:hidden sm:text-lg text-base p-4  text-justify'>At <span className='text-Summer font-bold'>Perceptions Technologies</span>, we are passionate about transforming your digital dreams into reality. Our Website/Software Development services are designed to cater to businesses of all sizes, from start-ups to established enterprises.</p>
      
        </div>

      <Lottie className='sm:basis-1/2 mb-5' animationData={Web} />

      </div>
  
      <p className='lg:hidden sm:text-lg text-base'>At <span className='text-Summer font-bold'>Perceptions Technologies</span>, we are passionate about transforming your digital dreams into reality. Our Website/Software Development services are designed to cater to businesses of all sizes, from start-ups to established enterprises.</p>
    </div>
  )
}

export default Digital
