import React from 'react'
import logo from '../../assets/logo/perceptions.png'
import { BiLogoLinkedinSquare } from 'react-icons/bi'
import { BiLogoInstagramAlt } from 'react-icons/bi'
import { Link } from 'react-router-dom'


function Footer() {
  const Top = () => {
    window.scrollTo(0, 0);
  }
  return (
    <div className=' mt-11 rp font-Inter text-Whitefooter'>
      <div className=' grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-9 gap-x-12'>
        <div className='flex'>
          <img src={logo} alt="logo" width={150} />
        </div>
        <div className='flex '>
          <div>

            <h2 className='py-5 font-semibold text-xl text-LightSky font-Inter'>Quick Links</h2>
            <Link to='/'><button onClick={Top} className=' text-sm w-full text-start hover:text-white py-1'>Home</button></Link>
            <Link to='/about'><button onClick={Top} className=' text-sm w-full text-start hover:text-white py-1'>About</button></Link>
            <Link to='/services'><button onClick={Top} className=' text-sm w-full text-start hover:text-white py-1'>Services</button></Link>
            <Link to='/case-study'><button onClick={Top} className=' text-sm w-full text-start hover:text-white py-1'>Case Study</button></Link>
            <Link to='/contact-us'><button onClick={Top} className=' text-sm w-full text-start hover:text-white py-1'>Contact Us</button></Link>
            <Link to='/teams'><button onClick={Top} className=' text-sm w-full text-start hover:text-white py-1'>Teams</button></Link>
          </div>

        </div>
        <div className='flex '>
          <div>
            <h2 className='py-5 font-semibold text-xl text-LightSky font-Inter'>Important Links</h2>
            <Link to='/PrivacyPolicy'><button onClick={Top} className=' text-sm w-full text-start hover:text-white'>Terms and Conditions</button></Link>


          </div>
        </div>
        <div className='sm:flex break-words'>
          <div>
            <h2 className='py-4 font-semibold text-xl text-LightSky font-Inter'>Let's work together</h2>
            <p className=' text-sm '>Write to us at:</p>
            <p className='text-sm break-words'>services@perceptionstechnologies.com</p>

            <div className='flex mt-4'>

              <Link to={'https://www.linkedin.com/company/perceptions-technologies/'} target="_blank" aria-label="LinkedIn Profile" rel="noopener noreferrer">   <BiLogoLinkedinSquare className=' h-9 w-9' /></Link>
              <Link to={'https://www.instagram.com/perceptions_technologies_/'} target="_blank" aria-label="Instagram Profile" rel="noopener noreferrer">  <BiLogoInstagramAlt className=' h-9 w-9' /></Link>
            </div>

          </div>
        </div>
      </div>

      <div className=" border-t-2 border-Topaz mt-16 "></div>

      <div className='col-span-1 md:col-span-4 md:col-start-3 md:order-2 text-center md:text-right my-4 '>
        <p className='text-whitefooter text-right text-[10px]  mr-1 font-Josefin'>
          Copyright 2023 © Perceptions Technologies | All Rights Reserved | Privacy Policy
        </p>
      </div>
    </div>

  )
}

export default Footer
