import React, { useState, useEffect } from 'react';
import Logo from '../../assets/logo/perceptions.png';
import { Link, useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { Sling as Hamburger } from 'hamburger-react';
import INSIGHT_LOGO from '../../assets-insightsnest/NAV-LOGO.png'

function Navbar() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const location = useLocation();

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 640);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (isNavVisible && window.innerWidth <= 640) {
        setIsNavVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isNavVisible]);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const closeNav = () => {
    setIsNavVisible(false);
    window.scrollTo(0, 0);
  };

  const smoothScrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: 'easeInOutQuart',
    });
  };

  const isSamePage = (path) => {
    return location.pathname === path;
  };

  return (
    <div className={`font-Poppins ${isSmallScreen ? 'sticky top-0 z-10 w-full bg-black bg-opacity-80' : 'w-full top-0 sticky bg-black bg-opacity-50 z-10 flex justify-between items-center px-10'}`}>
      <div className='flex justify-between items-center sm:w-auto'>
        <Link to="/" onClick={isSamePage('/') ? smoothScrollToTop : closeNav} className='px-3'>
          <img className='sm:w-[65px] w-10' src={Logo} alt='logo' />
        </Link>
        {isSmallScreen && (
          <div className="px-3">
            <Hamburger
              toggled={isNavVisible}
              toggle={toggleNav}
              duration={0.8}
              size={22}
              color="#38B6FF"
              aria-label="Open Menu"
            />
          </div>
        )}
      </div>

      <ul
        className={`${
          isSmallScreen
            ? (isNavVisible ? 'flex flex-col gap-y-5 absolute bg-black w-full bg-opacity-80 px-6 py-5 -mt-[1px]' : 'hidden')
            : 'flex md:text-base uppercase lg:gap-24 md:gap-14 sm:gap-3'
        }`}
      >
        {[
          { path: '/', label: 'Home' },
          { path: '/about', label: 'About Us' },
          { path: '/services', label: 'Services' },
          { path: '/case-study', label: 'Case Study' },
          { path: '/contact-us', label: 'Contact Us' },
        ].map((item) => (
          <Link
            key={item.path}
            className={isSamePage(item.path) ? 'active' : 'hover:text-Whitefooter'}
            to={item.path}
            onClick={isSamePage(item.path) ? smoothScrollToTop : closeNav}
          >
            {item.label}
          </Link>
        ))}
        {isSmallScreen && isNavVisible && (
          <div className='flex justify-start'>
            <Link to='/insightsnest'>
              <button onClick={closeNav} className='relative sm:text-base sm:px-3 px-0 py-2 sm:rounded-3xl overflow-hidden group bg-white rounded-full'>
                <span className='absolute h-full bg-Summer bg-opacity-40 transition-all duration-300 group-hover:w-full z-0'></span>
                <img src={INSIGHT_LOGO} alt="Insightsnest Logo" className=" w-20 px-2" />
              </button>
            </Link>
          </div>
        )}
      </ul>

      {!isSmallScreen && (
        <div className='uppercase'>
          <Link to='/insightsnest'>
            <button onClick={closeNav} className='relative sm:text-base sm:px-3 px-0 py-2 sm:rounded-3xl overflow-hidden group bg-white'>
              <span className='absolute left-0 top-0 w-0 h-full bg-Summer bg-opacity-40 transition-all duration-300 group-hover:w-full z-0'></span>
              <img src={INSIGHT_LOGO} alt="Insightsnest Logo" className=" w-28  " />
            </button>
          </Link>
        </div>
      )}
    </div>
  );
}

export default Navbar;
