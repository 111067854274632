import React from 'react'
import Hero from '../../assets/servicemain/Hero.jpeg'
import './Hero.css'




function Header() {
  return (
    <div >
     <div>
     <div className=' mb-10'>
      <div className='flex justify-center relative'>
        <img className=' w-full ' src={Hero} alt="bring inspiration and innovation.png"/>
        <div className='absolute top-1/4 content w-full'>
        <div>
	<svg className='svvg w-1/2 sm:h-1/6 h-10 mx-auto bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-40'>
		<text className=' svg_text lg:text-7xl sm:text-3xl text-xl w-full' x="50%" y="50%" dy=".35em" textAnchor="middle">
			Our Services
		</text>
	</svg>
</div>
        </div>
      
      </div>
    </div>
      </div>
    </div>
  )
}

export default Header
