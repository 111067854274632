import React from 'react'

function AppDevContentSecond() {
  return (
    <div className=' p-5 top-40 flex justify-center items-center sm:mb-32 mb-10'>
        <div className='  grid max-[1060px]:grid-cols-2 max-[540px]:grid-cols-1 grid-cols-4 gap-x-11 gap-y-11 '>

<div  className=''>
<div className="cardbox">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <div>
    <svg className="check" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
      
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path>
    </svg>
    <strong >Legacy Application Modernization</strong>
    <p className='text-white pt-7'>Transform your legacy applications into modern, future-proof solutions. Our expert developers update and revamp legacy systems, enhancing their functionality and user experience.
    </p>
    
    
  </div>
</div> 
</div>
<div  className=''>
<div className="cardbox">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <div>
    <svg className="check" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
      
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path>
    </svg>
    <strong >Quality Assurance and Testing</strong>
    <p className='text-white pt-7'>
    Our meticulous testing processes ensure that your applications are free from bugs and errors. We rigorously test for performance, security, and compatibility to deliver a flawless end product.
    </p>
    
    
  </div>
</div>
</div>
<div  className=''>
<div className="cardbox">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <div>
    <svg className="check" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
      
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path>
    </svg>
    <strong >User Training and Support</strong>
    <p className='text-white pt-7'>
    We don't just deliver the application; we empower your team with the knowledge and support they need to make the most of the new software. Our training and support services ensure a smooth transition and adoption.
    </p>
    
    
  </div>
</div>

</div>
<div  className=''>
<div className="cardbox">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <div>
    <svg className="check" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
      
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path>
    </svg>
    <strong >Continuous Improvement</strong>
    <p className='text-white pt-7'>We believe in the iterative development process. Even after deployment, we monitor application performance and gather user feedback to make continuous improvements and enhancements.
    </p>
    
    
  </div>
</div>
</div>

</div>

    </div>
  )
}

export default AppDevContentSecond
