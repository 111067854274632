import React from 'react'
import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill } from "react-icons/bs";
import work from '../../../assets-insightsnest/how-it-work.svg'



const Work = () => {
  return (
    <div className=' rp flex max-lg:flex-col-reverse lg:flex-row  justify-center items-center bg-blue-500 sm:h-[80vh] mb-36 text-white ' data-aos="fade-up" >
      <div className=' w-full  sm:m-0 mb-3 flex justify-center ' data-aos="zoom-in-up" data-aos-delay="500" >
        <img src={work} alt="" className="w-full md:w-[70%] " />
      </div>
      <div className=' flex flex-col w-full md:w-[70%] sm:mb-0  'data-aos="zoom-in-down" data-aos-delay="400" >
       <div className=' mb-4 '>
        <p className=' sm:text-4xl text-2xl font-semibold mb-8 max-lg:text-center pt-10'>
            How it Work
        </p>
        <p className=' sm:text-xl text-base '>
        This is the best platform where you can find guidance very easily without any difficulty. It's very simple and easy way where you can find your dream job and solution to your family matters.
        </p>
       </div>
       {/* 1  */}
       <div className=" flex flex-row mb-3 ">
        <div>
            <span>
                <Bs1CircleFill className=' font-bold sm:text-xl  text-lg mt-1 mr-2' />
            </span>
        </div>
        <div>
            <p className=" text-xl font-semibold">Fill the form</p>
            {/* <p className="sm:text-lg text-base">This is the best platform where you can find guidance very easily without any difficulty.</p> */}
        </div>
       </div>
       {/* 2  */}
       <div className=" flex flex-row mb-3 ">
        <div>
            <span>
                <Bs2CircleFill className=' font-bold text-xl mt-1 mr-2' />
            </span>
        </div>
        <div>
            <p className=" text-xl font-semibold">Join our exclusive webinar</p>
            {/* <p className="sm:text-lg text-base">This is the best platform where you can find guidance very easily without any difficulty.</p> */}
        </div>
       </div>
       {/* 3  */}
       <div className=" flex flex-row ">
        <div>
            <span>
                <Bs3CircleFill className=' text-xl font-bold mt-1 mr-2' />
            </span>
        </div>
        <div>
            <p className=" text-xl font-semibold">Complete Payments</p>
            {/* <p className="sm:text-lg text-base">This is the best platform where you can find guidance very easily without any difficulty.</p> */}
        </div>
       </div>
      </div>
    </div>
  )
}

export default Work
