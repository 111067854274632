import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {IoIosArrowDroprightCircle} from 'react-icons/io'
import NET from 'vanta/src/vanta.net';

function Hero() {
  // Typewriter effect
  const [typedText, setTypedText] = useState('');
  const [isCursorVisible, setIsCursorVisible] = useState(true); // Track cursor visibility
  const typingSpeed = 120;

  const textToType = useMemo(
    () => ['Web Development', 'App Development', 'UI/UX Design'],
    []
  );

  useEffect(() => {
    let wordIndex = 0;
    let charIndex = 0;

    const typingInterval = setInterval(() => {
      if (charIndex <= textToType[wordIndex].length) {
        setTypedText((prevTypedText) =>
          textToType[wordIndex].substr(0, charIndex + 1)
        );
        charIndex++;
      } else {
        charIndex = 0;
        wordIndex = (wordIndex + 1) % textToType.length;
        setTypedText('');
      }
    }, typingSpeed);

    const cursorInterval = setInterval(() => {
      setIsCursorVisible((prev) => !prev); // Toggle cursor visibility
    }, 500); // Blink every 500ms

    return () => {
      clearInterval(typingInterval);
      clearInterval(cursorInterval);
    };
  }, [textToType]);

  // Using Vanta
  useEffect(() => {
    const netEffect = NET({
      el: '#vanta',
      minHeight: 100,
      minWidth: 200.0,
      maxDistance: 15.00,
      scale: 1,
      scaleMobile: 1,
      color: 0x89ff,
      points: 16,
      backgroundColor: 0x060014,
    });

    return () => {
      if (netEffect) {
        netEffect.destroy();
      }
    };
  }, []);

  return (
    <div className="text-white top-0 sm:mb-28 mb-10 font-bold">
      <div className="md:h-[90vh] sm:h-[50vh] h-[40vh] w-full relative top-0" id="vanta">
        <div className="top-[40%] absolute left-[5%] sm:text-5xl text-xl max-[243px]:text-sm">
          Grow Your Business
          <div>
            with <span className="text-Summer">{typedText}</span>
            {isCursorVisible && <span className="animate-blink-cursor text-Summer">|</span>}
          </div>
          <p className="sm:text-2xl text-sm max-[243px]:text-sm">Your Dreams, Our Desire.</p>
          <Link to='/insightsnest'>
          <button className="mt-2 text-sm cssbuttons-io-button">
              Get started
              <div className="icon">
                <IoIosArrowDroprightCircle className="svg" />
              
              </div>
            </button>

          </Link>
        </div>
      </div>
    </div>
  );
}

export default Hero;
