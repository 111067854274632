import React from 'react'
import SEO from '../../../../assets/services/SEO.png'
import PPC from '../../../../assets/services/PPC.png'
import CM from '../../../../assets/services/CM.png'
import EM from '../../../../assets/services/EM.png'
import SMM from '../../../../assets/services/SMM.png'
import ORM from '../../../../assets/services/ORM.png'
import DA from '../../../../assets/services/DA.png'
import MC from '../../../../assets/services/MC.png'
import IM from '../../../../assets/services/IM.png'
import { Link } from 'react-router-dom'



function DigitalContent() {
  const ScrollTop = () => {
    window.scrollTo(0,0);
  }
  return (
    <div className='rp mb-20 font-Poppins break-words'>
      <div className="text-center py-8 mb-12">
  <h2 className="sm:text-3xl text-xl mb-4">Elevate Your Business with Our Digital Marketing Services</h2>
  <p className="sm:text-lg text-sm text-gray-300 leading-relaxed ">Our digital marketing offerings cover a wide spectrum of services meticulously crafted to align with your unique business objectives:</p>
</div>

        
      
        <div className='sm:flex justify-between sm:mb-32 mb-10 gap-x-8 sm:text-base text-sm'>

        <div
  className='sm:flex gap-y-2 flex-col justify-center sm:w-2/5 w-5/6 sm:mx-0 mx-auto sm:mb-0 mb-16 p-2'
  style={{
    boxShadow:
      'rgba(56, 182, 255, 0.4) -5px 5px, ' +
      'rgba(56, 182, 255, 0.3) -10px 10px, ' +
      'rgba(56, 182, 255, 0.2) -15px 15px, ' +
      'rgba(56, 182, 255, 0.1) -20px 20px, ' +
      'rgba(56, 182, 255, 0.05) -25px 25px',
  }}
>
                <div className='sm:mb-0 mb-10'>
                     <h2 className='text-center text-xl mb-5 text-Summer font-bold'>Search Engine Optimization</h2>
                     <p className=' text-base sm:mb-8'>Boost your website's visibility and organic traffic with our results-driven SEO strategies. Our experts optimize your website, conduct thorough keyword research, and implement on-page and off-page SEO techniques to rank you higher on search engine results.</p>
                </div>
                    <img className=' w-1/2 mx-auto' src={SEO} alt="SEO" />
            </div>


            <div className='flex gap-y-2 sm:flex-col flex-col-reverse justify-center sm:w-2/5 w-5/6  sm:mx-0 mx-auto sm:mb-0 mb-20 p-2'
            style={{
                boxShadow:
                  'rgba(56, 182, 255, 0.4) 5px -5px, ' +
                  'rgba(56, 182, 255, 0.3) 10px -10px, ' +
                  'rgba(56, 182, 255, 0.2) 15px -15px, ' +
                  'rgba(56, 182, 255, 0.1) 20px -20px, ' +
                  'rgba(56, 182, 255, 0.05) 25px -25px',
              }}
              
              
            >
                    <img className=' w-1/2 mx-auto sm:mb-8' src={PPC} alt="PPC" />
                    <div className='sm:mb-0 mb-10'>
                     <h2 className='text-center text-xl mb-5 text-Summer font-bold'>Pay Per Click</h2>
                     <p className=' text-base sm:mb-0'>Get instant visibility and targeted traffic through our PPC advertising campaigns. We create and manage PPC campaigns across platforms like Google Ads and social media to ensure maximum return on investment (ROI).</p>
                </div>
            </div>
            
        </div>

        
        
      
        <div className='sm:flex justify-between sm:mb-32 mb-10 gap-x-8'>

        <div
  className='sm:flex gap-y-2 flex-col justify-center sm:w-2/5 w-5/6 sm:mx-0 mx-auto sm:mb-0 mb-16 p-2'
  style={{
    boxShadow:
      'rgba(56, 182, 255, 0.4) -5px 5px, ' +
      'rgba(56, 182, 255, 0.3) -10px 10px, ' +
      'rgba(56, 182, 255, 0.2) -15px 15px, ' +
      'rgba(56, 182, 255, 0.1) -20px 20px, ' +
      'rgba(56, 182, 255, 0.05) -25px 25px',
  }}
>
               <div className='sm:mb-0 mb-10'>
                     <h2 className='text-center text-xl mb-5 text-Summer font-bold'>Social Media Marketing</h2>
                     <p className=' text-base sm:mb-8'>Engage and connect with your audience on social media platforms. Our social media marketing experts craft compelling content and run strategic campaigns to build brand awareness, foster engagement, and drive conversions.</p>
                </div>
                    <img className=' w-1/2 mx-auto' src={SMM} alt="CM" />
            </div>


            <div className='flex gap-y-2 sm:flex-col flex-col-reverse justify-center sm:w-2/5 w-5/6  sm:mx-0 mx-auto sm:mb-0 mb-20 p-2'
            style={{
                boxShadow:
                  'rgba(56, 182, 255, 0.4) 5px -5px, ' +
                  'rgba(56, 182, 255, 0.3) 10px -10px, ' +
                  'rgba(56, 182, 255, 0.2) 15px -15px, ' +
                  'rgba(56, 182, 255, 0.1) 20px -20px, ' +
                  'rgba(56, 182, 255, 0.05) 25px -25px',
              }}
              
              
            >
                    <img className=' w-1/2 mx-auto sm:mb-8' src={CM} alt="CM" />
                    <div className='sm:mb-0 mb-10'>
                     <h2 className='text-center text-xl mb-5 text-Summer font-bold'>Content Marketing</h2>
                     <p className=' text-base sm:mb-0'>Content is king, and we know how to make it work for you. Our content marketing strategies encompass informative blog posts, engaging infographics, captivating videos, and more to position your brand as an authority in your industry.</p>
                </div>
            </div>

        </div>

        
        
      
        <div className='sm:flex justify-between sm:mb-32 mb-10 gap-x-8'>

        <div
  className='sm:flex gap-y-2 flex-col justify-center sm:w-2/5 w-5/6 sm:mx-0 mx-auto sm:mb-0 mb-16 p-2'
  style={{
    boxShadow:
      'rgba(56, 182, 255, 0.4) -5px 5px, ' +
      'rgba(56, 182, 255, 0.3) -10px 10px, ' +
      'rgba(56, 182, 255, 0.2) -15px 15px, ' +
      'rgba(56, 182, 255, 0.1) -20px 20px, ' +
      'rgba(56, 182, 255, 0.05) -25px 25px',
  }}
>
            <div className='sm:mb-0 mb-10'>
                     <h2 className='text-center text-xl mb-5 text-Summer font-bold'>Email Marketing</h2>
                     <p className=' text-base sm:mb-8'>Nurture your leads and keep your audience informed with our effective email marketing campaigns. We create personalized and engaging email content to drive customer loyalty and repeat business.</p>
                </div>
                    <img className=' w-1/2 mx-auto' src={EM} alt="EM" />
            </div>


            <div className='flex gap-y-2 sm:flex-col flex-col-reverse justify-center sm:w-2/5 w-5/6  sm:mx-0 mx-auto sm:mb-0 mb-20 p-2'
            style={{
                boxShadow:
                  'rgba(56, 182, 255, 0.4) 5px -5px, ' +
                  'rgba(56, 182, 255, 0.3) 10px -10px, ' +
                  'rgba(56, 182, 255, 0.2) 15px -15px, ' +
                  'rgba(56, 182, 255, 0.1) 20px -20px, ' +
                  'rgba(56, 182, 255, 0.05) 25px -25px',
              }}
              
              
            >
                    <img className=' w-1/2 mx-auto sm:mb-8' src={IM} alt="IM" />
                    <div className='sm:mb-0 mb-10'>
                     <h2 className='text-center text-xl mb-5 text-Summer font-bold'>Influencer Marketing</h2>
                     <p className=' text-base sm:mb-0'>Amplify your brand's reach with influencer marketing. Our team identifies relevant influencers and collaborates with them to spread your message to their engaged audiences.</p>
                </div>
            </div>

        </div>

        
        
      
        <div className='sm:flex justify-between sm:mb-32 mb-10 gap-x-8'>

        <div
  className='sm:flex gap-y-2 flex-col justify-center sm:w-2/5 w-5/6 sm:mx-0 mx-auto sm:mb-0 mb-16 p-2'
  style={{
    boxShadow:
      'rgba(56, 182, 255, 0.4) -5px 5px, ' +
      'rgba(56, 182, 255, 0.3) -10px 10px, ' +
      'rgba(56, 182, 255, 0.2) -15px 15px, ' +
      'rgba(56, 182, 255, 0.1) -20px 20px, ' +
      'rgba(56, 182, 255, 0.05) -25px 25px',
  }}
>
            <div className='sm:mb-0 mb-10'>
                     <h2 className='text-center text-xl mb-5 text-Summer font-bold'>Conversion Rate Optimization </h2>
                     <p className=' text-base sm:mb-8'>Maximize your website's potential by improving its conversion rates. Our CRO experts analyze user behavior, implement A/B testing, and optimize your website to convert visitors into valuable customers.</p>
                </div>
                    <img className=' w-1/2 mx-auto' src={SMM} alt="CM" />
            </div>


            <div className='flex gap-y-2 sm:flex-col flex-col-reverse justify-center sm:w-2/5 w-5/6  sm:mx-0 mx-auto sm:mb-0 mb-20 p-2'
            style={{
                boxShadow:
                  'rgba(56, 182, 255, 0.4) 5px -5px, ' +
                  'rgba(56, 182, 255, 0.3) 10px -10px, ' +
                  'rgba(56, 182, 255, 0.2) 15px -15px, ' +
                  'rgba(56, 182, 255, 0.1) 20px -20px, ' +
                  'rgba(56, 182, 255, 0.05) 25px -25px',
              }}
              
              
            >
                    <img className=' w-1/2 mx-auto sm:mb-8' src={ORM} alt="CM" />
                    <div className='sm:mb-0 mb-10'>
                     <h2 className='text-center text-xl mb-5 text-Summer font-bold'>Online Reputation Management </h2>
                     <p className=' text-base sm:mb-0'>Protect and enhance your brand's online reputation with our ORM services. We monitor your brand's mentions, address negative feedback, and promote positive reviews to build trust and credibility.</p>
                </div>
            </div>

        </div>

        
        
      
        <div className='sm:flex justify-between sm:mb-32 mb-20 gap-x-8'>

        <div
  className='sm:flex gap-y-2 flex-col justify-center sm:w-2/5 w-5/6 sm:mx-0 mx-auto sm:mb-0 mb-16 p-2'
  style={{
    boxShadow:
      'rgba(56, 182, 255, 0.4) -5px 5px, ' +
      'rgba(56, 182, 255, 0.3) -10px 10px, ' +
      'rgba(56, 182, 255, 0.2) -15px 15px, ' +
      'rgba(56, 182, 255, 0.1) -20px 20px, ' +
      'rgba(56, 182, 255, 0.05) -25px 25px',
  }}
>
            <div className='sm:mb-0 mb-10'>
                     <h2 className='text-center text-xl mb-5 text-Summer font-bold'>Analytics and Reporting </h2>
                     <p className=' text-base sm:mb-8'>Data-driven decision-making is at the core of our digital marketing strategies. We provide detailed analytics and regular performance reports, allowing you to measure the effectiveness of our campaigns and make informed choices.</p>
                </div>
                    <img className=' w-1/2 mx-auto' src={DA} alt="CM" />
            </div>


            <div className='flex gap-y-2 sm:flex-col flex-col-reverse justify-center sm:w-2/5 w-5/6  sm:mx-0 mx-auto sm:mb-0 mb-20 p-2'
            style={{
                boxShadow:
                  'rgba(56, 182, 255, 0.4) 5px -5px, ' +
                  'rgba(56, 182, 255, 0.3) 10px -10px, ' +
                  'rgba(56, 182, 255, 0.2) 15px -15px, ' +
                  'rgba(56, 182, 255, 0.1) 20px -20px, ' +
                  'rgba(56, 182, 255, 0.05) 25px -25px',
              }}
              
              
            >
                    <img className=' w-1/2 mx-auto sm:mb-8' src={MC} alt="CM" />
                    <div className='sm:mb-0 mb-10'>
                     <h2 className='text-center text-xl mb-5 text-Summer font-bold'>Digital Marketing Consultation</h2>
                     <p className=' text-base sm:mb-0'>Beyond execution, we offer expert guidance and consultation on digital marketing strategies tailored to your unique business needs. We're here to be your trusted advisors on your digital journey.</p>
                </div>
            </div>

        </div>

        <p className='text-lg text-gray-400 leading-7 mb-10'>
        With a team of passionate digital marketers and a track record of driving success, Perceptions Technologies is your reliable partner in navigating the dynamic digital landscape.
Let's work together to elevate your brand and achieve remarkable online growth. Contact us now to embark on your Digital Marketing journey with Perceptions Technologies!
        </p>


        
        <div className='sm:flex justify-between mb-10'>

      <Link to='/UiUx'>  <button onClick={ScrollTop} className=" mb-10 bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition-all duration-500 ease-in-out hover:scale-110 hover:brightness-110 hover:animate-pulse active:animate-bounce">
  Previous Service
</button></Link>

      <Link to='/Blockchain'>  <button onClick={ScrollTop} className="  bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition-all duration-500 ease-in-out hover:scale-110 hover:brightness-110 hover:animate-pulse active:animate-bounce">
  Next Service
</button></Link>
</div>
      
        
        
      
        

       
    </div>
  )
}

export default DigitalContent
