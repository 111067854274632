import React, { useState, useEffect } from 'react';
import Logo from '../../assets/logo/perceptions.png'

function PreLoader() {
  const [typedText, setTypedText] = useState('');
  const textToType = '   PERCEPTIONS TECHNOLOGIES';
  const typingSpeed = 50; 

  useEffect(() => {
    let charIndex = 0;
    const typingInterval = setInterval(() => {
      if (charIndex < textToType.length) {
        setTypedText(prevTypedText => prevTypedText + textToType.charAt(charIndex));
        charIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, typingSpeed);

    return () => {
      clearInterval(typingInterval);
    };
  }, []);

  return (
    
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-black">
      <img
        src={Logo}
        alt="Perceptions Technologies Logo"
        className="w-20 h-20"
      />
      <p className="text-Summer sm:text-lg text-sm mt-2">{typedText}</p>
    </div>
    
  );
}

export default PreLoader
