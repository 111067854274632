import React from 'react'
import ChatBotHero from '../components/analyticsbots/ChatBotHero'
import ChatBotCards from '../components/analyticsbots/ChatBotCards'
import Analyticshero from '../components/analyticsbots/Analyticshero'
import AnalyticsCards from '../components/analyticsbots/AnalyticsCards'
import ChooseUs from '../components/analyticsbots/ChooseUs'
import { Helmet } from 'react-helmet'

function ChatBot() {
  return (
    <div>
       <Helmet>
      <title>
        Analytics & Bot - Perceptions Technologies
      </title>
    </Helmet>
      <ChatBotHero/>
      <ChatBotCards/>
      <Analyticshero/>
      <AnalyticsCards/>
      <ChooseUs/>
    </div>
  )
}

export default ChatBot
