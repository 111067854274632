import React from 'react'
import BlockchainHeader from '../components/blockchain/BlockchainHeader'
import Whatisblockchain from '../components/blockchain/Whatisblockchain'
import WhyBlockchainMatters from '../components/blockchain/WhyBlockchainMatters'
import BlockchainServices from '../components/blockchain/BlockchainServices'
import ServicesCards from '../components/blockchain/ServicesCards' 
import WhyusBlockchain from '../components/blockchain/WhyusBlockchain'
import Whayuscards from '../components/blockchain/Whayuscards'
import BlockchainRevolution from '../components/blockchain/BlockchainRevolution'
import { Helmet } from 'react-helmet'

function Blockchain() {
  return (
    <div>
       <Helmet>
      <title>
        Blockchain - Perceptions Technologies
      </title>
    </Helmet>
      <BlockchainHeader/>
      <Whatisblockchain/>
      <WhyBlockchainMatters/>
      <BlockchainServices/>
      <ServicesCards/> 
      <WhyusBlockchain/>
      <Whayuscards/>
      <BlockchainRevolution/>
    </div>
  )
}

export default Blockchain
