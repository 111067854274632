import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { servicesData } from '../Pages/Home/Services';
import emailjs from 'emailjs-com';
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, Link } from 'react-router-dom';
import { BiLogoLinkedinSquare } from 'react-icons/bi'
import { BiLogoInstagramAlt } from 'react-icons/bi'

const ServicesForm = () => {
    const { id } = useParams();
    const [service, setService] = useState(null);

    useEffect(() => {
        const foundService = servicesData.find((service) => service.id === id);
        setService(foundService);
    }, [id]);

    const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    const PHONE_REGEX = /^[0-9]{10}$/
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();


        const { name, lastName, email, phone, message } = formData;

        if (!name) {
            return toast.error("Please enter name");
        }
        if (!email) {
            return toast.error("Please enter email");
        }
        if (!EMAIL_REGEX.test(email)) {
            console.log("dwdwd", name, lastName, email, phone, message);
            return toast.error("Please enter a valid email address");
        }
        if (!phone) {
            return toast.error("Please enter phone number");
        }
        if (!service) {
            return toast.error("Please enter services");
        }
        if (!PHONE_REGEX.test(phone)) {
            return toast.error("Please enter a valid phont number");
        }
        if (!message) {
            return toast.error("Please enter message");
        }


        //  Ensure all required fields are filled
        if (!name || !email || !phone || !service || !message) {
            toast.error.error('Please enter a valid email address.');
            return;
        }




        const templateParams = {
            from_name: name,
            from_email: email,
            from_phone: phone,
            from_service: service.services,
            from_message: message
        };

        try {
            const serviceId = 'service_gtqmozt';
            const templateId = 'template_ifs92ph';
            const userId = 'e3y1bpwe5pg1A367y';
            await emailjs.send(serviceId, templateId, templateParams, userId);

            // If the email is sent successfully, show a success message
            toast.success("Email sent successfully");

            // Clear form data after successful submission
            setFormData({
                name: '',
                email: '',
                phone: '',
                service: service.services,
                message: ''
            });

            setTimeout(() => {
                navigate('/insightsnest');
            }, 2000);
        } catch (error) {
            console.error('Email sending failed:', error);
            toast.error('Failed to send message. Please try again later.');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className='mb-36 md:px-14'>
            <Toaster />

            {service ? (
                <>
                    <div id='services form' className="flex justify-center items-center mt-8 w-full bg-white py-5  " data-aos="fade-down">
                        <div className=" w-full mx-auto my-8 px-4 lg:px-20">
                        <div
                        className="w-full bg-blue-500 p-8 my-4 md:px-12 lg:w-1/3 lg:pl-20 lg:pr-40  mr-auto rounded-2xl shadow-2xl" data-aos="slide-up" data-aos-delay="200" >
                        <div className="flex flex-col text-white">

                            <div className="flex my-4 w-2/3 lg:w-3/4">
                                <div className="flex flex-col">
                                    <i className="fas fa-map-marker-alt pt-2 pr-2" />
                                </div>
                                <div className="flex flex-col" data-aos="zoom-in" data-aos-delay="500">
                                    <h2 className="text-2xl">Address</h2>
                                    <p className="text-gray-300">Indore</p>
                                </div>
                            </div>

                            <div className="flex my-4 w-2/3 lg:w-1/2">
                                <div className="flex flex-col">
                                    <i className="fas fa-phone-alt pt-2 pr-2" />
                                </div>

                                <div className="flex flex-col" data-aos="zoom-in" data-aos-delay="500">
                                    <h2 className="text-2xl">Contact Us</h2>
                                    <p className="text-gray-300">9620864378</p>

                                    <div className='mt-5' data-aos="zoom-in" data-aos-delay="500">
                                        <h2 className="text-2xl">Send an E-mail</h2>
                                        <p className="text-gray-300">insightsnest@gmail.com</p>
                                    </div>

                                </div>
                            </div>

                            <div className="flex my-4 w-2/3 lg:w-1/2" data-aos="zoom-in" data-aos-delay="500">
                            <Link to={'https://www.linkedin.com/showcase/insightsnest'} target="_blank" aria-label="LinkedIn Profile" rel="noopener noreferrer">   <BiLogoLinkedinSquare className=' h-9 w-9' /></Link>
                            <Link to={'https://www.instagram.com/insightnest.20/'} target="_blank" aria-label="Instagram Profile" rel="noopener noreferrer">  <BiLogoInstagramAlt className=' h-9 w-9' /></Link>
                            </div>
                        </div>
                    </div>

                            <form onSubmit={handleSubmit}>
                                <div className="w-full  lg:-mt-96 px-8 py-6 ml-auto p-8 my-3 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 bg-white drop-shadow-xl rounded-2xl" data-aos="zoom-in" data-aos-delay="150">
                                    <div className="flex">
                                        <h1 className="font-bold  lg:text-left text-blue-500  text-3xl" data-aos="zoom-in" data-aos-delay="500">Form for {service.title}</h1>
                                    </div>
                                    <div className="grid grid-cols-1 gap-5 md:grid-cols-1 mt-5">
                                        <div>
                                            <input
                                                name="name"
                                                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" data-aos="zoom-out" data-aos-delay="400"
                                                type="text"
                                                placeholder="Name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />

                                        </div>


                                    </div>
                                    <div className=' grid grid-cols-1 gap-5 md:grid-cols-1 mt-5'>

                                        <div>
                                            <input
                                                name="email"
                                                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" data-aos="zoom-out" data-aos-delay="400"
                                                type="email"
                                                placeholder="Email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>

                                        <div>
                                            <input
                                                name="phone"
                                                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" data-aos="zoom-out" data-aos-delay="400"
                                                type="tel"
                                                placeholder="Phone"
                                                maxLength={10} // Limit the maximum length to 10 characters
                                                pattern="[0-9]{10}" // Only allow exactly 10 numeric characters
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>

                                        {/* for services  */}
                                        <div>
                                            <input
                                                name="Service"
                                                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 font-semibold rounded-lg focus:outline-none focus:shadow-outline" data-aos="zoom-out" data-aos-delay="400"
                                                type="text"
                                                placeholder="service"
                                                value={service.services}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>

                                        <div className="">
                                            <textarea
                                                name="message"
                                                placeholder="Message*"
                                                className="w-full h-14 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" data-aos="zoom-out" data-aos-delay="400"
                                                value={formData.message}
                                                onChange={handleChange}
                                                required
                                            ></textarea>
                                        </div>


                                    </div>
                                    <div className="my-2 w-1/2 lg:w-2/4" data-aos="zoom-in" data-aos-delay="400">
                                        <button type="submit" id="submitBtn" className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-500 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </>
            ) : (
                <p className=' mt-36'>404 page not found</p>
            )}
        </div>
    );
};

export default ServicesForm;
