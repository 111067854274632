import React from 'react'
import img from '../../../assets-insightsnest/about-us.svg'
import { IoVideocam } from "react-icons/io5";
import { FaMicrophoneAlt } from "react-icons/fa";

const About = () => {
  return (
    <div className=' text-black'>
      <div className=' text-center mb-4' data-aos="zoom-in" data-aos-delay="100">
        <p className=' text-3xl font-bold text-blue-500'>Choose Guidance. Over Suffering.</p>
      </div>
      <div className=" rp flex flex-col-reverse lg:flex-row justify-between lg:text-left" >
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center items-center" data-aos="fade-up" data-aos-delay="400" >
                            <img alt="card img" className="rounded-t lg:w-[70%] w-full " src={img} />
                        </div>
                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" >
                            
                            <h3 className="text-2xl font-semibold mb-5 ">Counselling Sessions With Licensed & Verified Experts</h3>
                            <div className=' mb-8'>
                                <p className='my-3 text-justify text-xl text-gray-600 '>At our Counseling Hub, we pride ourselves on providing top-notch counseling services tailored to your needs. With decades of experience in nuclear science and a passion for helping others, our expert counselor offers invaluable insights and practical advice to empower individuals in their personal and professional lives.</p>
                            </div>
                             <div className=' flex gap-12  mb-3'>
                                <div className=' flex flex-col items-center'>
                                    <div className=' bg-blue-500  flex justify-center items-center rounded-full w-10 h-10 mb-1 hover:bg-blue-700'>
                                        <IoVideocam className=' text-white text-xl font-bold' />
                                    </div>
                                  <span className=' font-semibold '>
                                  Video session  
                                </span>   
                                </div>
                                <div className=' flex items-center flex-col'>
                                    <div className=' bg-blue-500  flex justify-center items-center rounded-full w-10 h-10 mb-1 hover:bg-blue-700'>
                                    <FaMicrophoneAlt className=' text-white text-xl font-bold'  />
                                    </div>
                                  <span className=' font-semibold'>
                                  Audio session  
                                </span>   
                                </div>
                             </div>
                             <div>
                                <p className=' font-semibold text-base'>
                                    100% Private & Secure Platform
                                </p>
                             </div>

                        </div>
                    </div>
    </div>
  )
}

export default About
