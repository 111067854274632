import React from 'react'
import whymatter from '../blockchain/Images/Bitcoin P2P-pana.svg'

const WhyBlockchainMatters = () => {
  return (
    <div className=' rp sm:flex justify-center items-center gap-24  mb-32 mt-7'>
      <div className=' flex flex-col justify-center items-center basis-[40%] sm:mb-0 mb-10 '>
        <p className=' uppercase sm:text-5xl text-2xl text-LightSky font-bold'>
        Why Blockchain <span className=' text-DarkGray'>Matters</span>
        </p>
        <p className=' mt-4  sm:text-lg text-base text-Gainsboro'>
        Blockchain is the backbone of cryptocurrencies like Bitcoin, but its applications extend 
        far beyond digital money. It is about creating trust in a thrustless world. Blockchain has 
        the potential to disrupt various sectors, from finance and supply chain to healthcare and beyond.
        </p>
      </div>
      <div className=' block md:w-2/3 w-full flex justify-center sm:m-0 mb-3 '>
        <img src={whymatter} alt="" className=" sm:w-[40vw] basis-[40%]" />
      </div>
    </div>
  )
}

export default WhyBlockchainMatters
