import React, { useState } from 'react';
import PA from '../../../../assets/services/PredictiveAnalytics.png';
import BI from '../../../../assets/services/BusinessIntelligence.png';
import DA from '../../../../assets/services/DataAnalysis.png';
import AB from '../../../../assets/services/Analyticsbot.png';

function AnalyticsCards() {
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const resetHover = () => {
    setHoveredIndex(-1);
  };

  const cardData = [
    {
      title: 'Data Analysis',
      image: DA,
      description:
        "We don't just collect data; we transform it into meaningful information. Our analytics tools provide a 360-degree view of your business, helping you make informed decisions.",
    },
    {
      title: 'Predictive Analytics',
      image: PA,
      description:
        'Want to know what the future holds? Our predictive analytics models can forecast trends, enabling you to stay ahead of the curve.',
    },
    {
      title: 'Business Intelligence',
      image: BI,
      description:
        "Get a real-time snapshot of your business's performance. Our BI dashboards provide easy-to-understand visualizations that drive smarter decision-making.",
    },
  ];

  const getCardClassName = (index) => {
    if (index === hoveredIndex) {
      return 'lg:w-1/5 md:w-1/2 sm:w-full p-4 flex flex-col gap-y-10 hover:bg-gray-500 rounded-tr-[100px] bg-gray-500';
    } else if (hoveredIndex === -1 && index === 0) {
      return 'lg:w-1/5 md:w-1/2 sm:w-full p-4 flex flex-col gap-y-10 bg-gray-500 rounded-tr-[100px]';
    }
    return 'lg:w-1/5 md:w-1/2 sm:w-full p-4 flex flex-col gap-y-10';
  };

  return (
    <div className='rp sm:mb-32 mb-10'>
      <div className='flex justify-between flex-col lg:flex-row gap-x-10 p-4 gap-y-16'>
        {cardData.map((card, index) => (
          <div
            key={index}
            className={getCardClassName(index)}
            onMouseEnter={() => handleHover(index)}
            onMouseLeave={resetHover}
          >
            <div className='p-4'>
              <img className='w-20' src={card.image} alt={`${card.title} png`} />
              {card.title}
            </div>
            <div className=' p-4'>{card.description}</div>
          </div>
        ))}
        <div className='lg:w-1/5 md:w-1/2 sm:w-full flex items-center'>
          <img src={AB} alt='Analytics PNG' />
        </div>
      </div>
    </div>
  );
}

export default AnalyticsCards;
