import React from 'react'
import Hero from './Hero'
import About from './About'
import Services from './Services'
import Work from './Work'


const Home = () => {
  return (
    <div>
      < Hero />
      < About />
      <Work />
      < Services />
    </div>
  )
}

export default Home
