import React from 'react';
import { Link } from 'react-router-dom'

function ChooseUs() {
  const ScrollTop = () => {
    window.scrollTo(0,0);
  }
  return (
    <div className='rp mb-28'>
      <div className='block sm:text-5xl text-2xl text-DarkGray mb-16'>
        Why Choose Us?
      </div>
      <div className=' flex flex-col gap-8 sm:flex-row mb-20 flex-wrap items-center'>
        <div className='mb-8 sm:mb-0 bg-Topaz p-4 w-full sm:w-96'>
          <h1 className='text-LightSky text-4xl mb-4'>01</h1>
          <h2 className='text-Summer text-2xl font-bold mb-4'>Expertise</h2>
          <p>
            Our team includes data wizards and bot whisperers who understand the intricate world of analytics and automation.
          </p>
        </div>

        <div className='mb-8 sm:mb-0 bg-Topaz p-4 w-full sm:w-96'>
          <h1 className='text-LightSky text-4xl mb-4'>02</h1>
          <h2 className='text-Summer text-2xl font-bold mb-4'>Custom Solutions</h2>
          <p>
            We don't do one-size-fits-all. We tailor analytics and bot solutions to meet your unique business needs.
          </p>
        </div>

        <div className='mb-8 sm:mb-0 bg-Topaz p-4 w-full sm:w-96'>
          <h1 className='text-LightSky text-4xl mb-4'>03</h1>
          <h2 className='text-Summer text-2xl font-bold mb-4'>Continuous Improvement</h2>
          <p>
            The digital landscape is ever-evolving. We stay up-to-date with the latest trends and technologies to keep your business ahead of the game.
          </p>
        </div>

        <div className='mb-8 sm:mb-0 bg-Topaz p-4 w-full sm:w-96'>
          <h1 className='text-LightSky text-4xl mb-4'>04</h1>
          <h2 className='text-Summer text-2xl font-bold mb-4'>Security</h2>
          <p>
            Data security is paramount. We implement robust measures to ensure your data remains safe and confidential.
          </p>
        </div>
      </div>



      <div className=' text-center mb-10'>
  <h1 className='sm:text-5xl text-lg text-Summer mb-6 font-bold'>
    Join the Data Revolution
  </h1>
  <p className='text-lg text-gray-400 leading-7'>
    In the age of data-driven decisions and automation, your business can't afford to be left behind. Perceptions Technologies is your partner in harnessing the full potential of analytics and bots.
  </p>
</div>


<Link to='/Blockchain'>  <button onClick={ScrollTop} className=" bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition-all duration-500 ease-in-out hover:scale-110 hover:brightness-110 hover:animate-pulse active:animate-bounce">
Previous Service
</button></Link>


    </div>
  );
}

export default ChooseUs;
