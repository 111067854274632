import React from 'react'

function WebServicedivs() {
  return (
    <div className='rp sm:mb-24 mb-16  '>
        <div className='sm:flex justify-between sm:mb-24 mb-16'>
<div className=' basis-5/12 sm:mb-0 mb-10 bg-Topaz p-4'>
<h1 className=' text-2xl text-center mb-4 text-Summer font-bold sm:mb-0'>
UI/UX Design
  </h1>
  <p className='md:text-base max-md:text-sm'>
  Our creative designers are experts in crafting visually appealing and intuitive user interfaces. We combine aesthetics with functionality to deliver seamless user experiences that leave a lasting impression.
  </p>
</div>

<div className=' basis-5/12 sm:mb-0 mb-10 bg-Topaz p-4'>
<h1 className=' text-2xl text-center mb-4 text-Summer font-bold'>
Mobile App Developmentt
  </h1>
  <p className='md:text-base max-md:text-sm'>
  Extend your reach to the mobile audience with our innovative mobile app development services. From Android to iOS, we build feature-rich, intuitive mobile apps that captivate users and deliver value.
  </p>
</div>
</div>



<div className='sm:flex justify-between sm:mb-28 mb-10'>
<div className=' basis-5/12 sm:mb-0 mb-10 bg-Topaz p-4'>
<h1 className=' text-2xl text-center mb-4 text-Summer font-bold'>
  Content Management Systems
  </h1>
  <p className='md:text-base max-md:text-sm'>
  Take full control of your website's content with our user-friendly CMS platforms. Update and manage your content effortlessly, empowering you to keep your online presence fresh and dynamic.
  </p>
</div>

<div className=' basis-5/12 sm:mb-0 mb-10 bg-Topaz p-4'>
<h1 className=' text-2xl text-center mb-4 text-Summer font-bold'>
  Enterprise Software Development
  </h1>
  <p className='md:text-base max-md:text-sm'>
  Streamline your business operations with our robust enterprise software solutions. We optimize processes, enhance collaboration, and facilitate data-driven decision-making to fuel your organization's growth.
  </p>
</div>
</div>
    </div>
  )
}

export default WebServicedivs
