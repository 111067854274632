import React from 'react'
import { Helmet } from 'react-helmet'
import SixServices from '../services/SixServices'
import ExtraServices from '../services/ExtraServices'
import Header from '../services/Header'
import Logos from '../services/pages/Logos'

function Services() {
  return (
    <>
    <Helmet>
      <title>
        Services - Perceptions Technologies
      </title>
    </Helmet>
    <div>
      <Header/>
      <SixServices/>
      <ExtraServices/>
      <Logos />
    
    </div>
    </>
  )
}

export default Services
