import React from 'react';
import image from '../../assets/home/aboutsection/aboutsection.svg';

function AboutSection() {
  return (
    <div className='rp font-Poppins sm:mb-24 mb-10 md:flex flex-row gap-2 block break-words   '>

      <div className=' md:w-1/2 w-full sm:flex flex-col justify-center'>
        <h1 className='sm:text-5xl text-2xl font-bold pb-8  text-Summer uppercase'>About Us</h1>
        <p className='text-lg sm:mb-0 mb-5'>
        We're a bunch of dreamers, code junkies, and problem solvers. We've got more keyboard shortcuts in our heads than we'd like to admit. And if "geeking out" were a competitive sport, we'd be gold medalists.
        </p>
      </div>
      <div className=' md:w-2/3 w-full flex justify-center sm:m-0 mb-3'>
        <img src={image} alt="About" className='md:w-full w-full item-center' />
      </div>
    </div>
  );
}

export default AboutSection;
