import React from 'react'
import aboutsvg from '../about/images/who we are.svg'

const whoweare = () => {
  return (
    <div className='rp mb-24 flex max-lg:flex-col lg:flex-row gap-2 bg-gradient-to-r from-[#000] to-[#8fc4f6] '>
    <div className='  w-full sm:flex md:flex flex-col justify-center sm:mb-0 mb-10'>
      <h1 className='sm:text-5xl text-2xl pb-8 mt-5 text-LightSky text-center  uppercase font-bold'>who we <span className=' text-[#a6a6a6]'>are...</span> </h1>
      <p className='sm:text-lg text-base mr-14  text-[#a6a6a6]'>
      Welcome to Perceptions Technologies! We're a group of tech lovers, problem solvers, and dreamers. We're really good with keyboard shortcuts, and if there was a competition for being tech-savvy, we'd win.
      </p>
      <p className=' sm:text-lg text-base mr-14 sm:mt-4 mt-3 text-[#a6a6a6]'>
      But here's the scoop: we're not your usual IT folks. We have big dreams, and we think you do too. That's why our motto is "Your Dreams, Our Desire." We're here to bring your digital dreams to life, all while secretly hoping for lots of snacks and cookies in the break room.
      </p>
    </div>
    <div className='  w-full  sm:m-0 mb-3'>
      <img src={aboutsvg} alt="About" className=' w-full' />
    </div>
  </div>
    
   
  )
}

export default whoweare
