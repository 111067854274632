import React from 'react'
import img from '../about/images/what we do.svg'


const Whatwe = () => {
  return (
    <div className=' rp flex max-lg:flex-col-reverse lg:flex-row  justify-center items-center '>
      <div className=' w-full  sm:m-0 mb-3 '>
        <img src={img} alt="" className=" w-full md:w-[70%] " />
      </div>
      <div className=' flex flex-col w-full md:w-[70%] sm:mb-0 mb-10 '>
        <h1 className=' sm:text-5xl text-2xl pb-8 text-LightSky text-start  uppercase font-bold'>
        What we do<span className=' text-[#a6a6a6]'>?</span>
        </h1>
        <p className=' sm:text-lg text-base text-[#a6a6a6]'>
        We're a versatile IT company, like a handy Swiss Army knife. We handle everything, from creating impressive websites to making AI Chat Bots that out-chat your office water cooler. If you want a stable mobile app or better SEO, we've got you covered. Think of it as a touch of magic with keywords.
        </p>
      </div>
      
      
  
    </div>
    
  )
}

export default Whatwe
