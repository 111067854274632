import React from 'react'
import IntroImg from '../../../../assets/services/19199146_prev_ui.png'


function UiUxIntro() {
  return (
    <div className='   flex justify-center items-center sm:mb-32 mb-10 '>
       <div className='p-2 '>
      <div className='grid grid-cols-1 md:grid-cols-2  bg-black h-5/6' >
        
          <div className='flex justify-center items-center'>
            <img src={IntroImg} alt="introimg" className='bg-black w-72 md:w-96 text-center ' />
          </div>
          <div className='flex flex-col justify-center items-center text-center'>
            <h1 className='text-3xl font-bold text-Summer font-Inter flex justify-center items-center '>Why Choose Us?</h1>
            <h2 className=' p-5 md:p-5'>At Perceptions Technologies, we believe that great design is not just about aesthetics; it's about crafting seamless, intuitive, and delightful user experiences. Our UI/UX Design services are at the heart of creating digital products that resonate with your users, boost engagement, and drive business success.
            Our UI/UX Design offerings encompass a comprehensive range of services tailored to meet your specific needs.</h2>
          </div>
      </div></div>
    </div>
  )
}

export default UiUxIntro
