import React, { useMemo } from 'react';
import {BsQuote} from 'react-icons/bs' 


function Testimonial() {
const testimonialData = useMemo(
  () => [
    {
    
      name: 'Pratiksha Naik',
      message:
      `"Perceptions Technologies has transformed our social media presence. From mouthwatering graphics to impactful marketing, they've truly sweetened our success!"`,
    },
    {
  
      name: 'Ashish Sharma',
     
      message:
        `"Perceptions Technologies brought our vision to life with a stunning website. Professional, responsive, and top-notch quality. We're thrilled with the results!"`,
    },
    {
    
      name: 'Sharif Dehlvi',
    
      message:
      `"Thanks to Perceptions Technologies, our store went digital seamlessly. Their e-commerce website and app are a game-changer making shopping a breeze for our customers"`,
    },
    {
     
      name: 'Rajeev Tekwana',
     message:
      `"Perceptions Technologies app streamlined our construction business. It's like they read our minds - efficient, user-friendly, and exactly what we needed."`,
    },
  ],
  []
);

function TestimonialCard({ name, message }) {
  return (
    <div className="bg-[#EAF0F1] p-4 rounded-lg shadow-md text-black py-10">
    <BsQuote className='mx-auto mb-4 text-3xl text-Summer'/>
      <h2 className="text-xl font-semibold text-center mb-3">{name}</h2>
      <p className="text-gray-700 mt-2">{message}</p>
    </div>
  );
}


  return (
  <div className='sm:mb-32 mb-10'>
    <h1 className='sm:text-5xl text-2xl font-bold pb-8 text-center text-Summer uppercase mb-10'>Testimonials</h1>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-6 rp">
      
      {testimonialData.map((testimonial, index) => (
        <TestimonialCard key={index} {...testimonial} />
      ))}
    </div>
    </div>
  );
}

export default Testimonial;