import React from 'react'
import './styleAnimeText.css'


function UiUxHeader() {
  return (
    <div className=' mt-10 sm:mb-32 mb-10'>

        


        
            <div className='grid gird-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-2'>
                <div className=''>
                     <div className=' grid grid-cols-1  gap-0'>
                        <div className='flex'>
                        <div className='border-[#E84D2A]  rounded-l-full border-2 h-36 w-36 transition-transform hover:scale-110'></div>
                        
                        
                        <div className='border-[#F76E5F]  rounded-r-full border-2 h-36 w-36'></div>
                        </div>
                        <div className='flex'>
                        <div className='border-[#9E56F7]  rounded-l-full border-2 h-36 w-36'></div>
                        
                        <div className='border-[#18B7F6]  rounded-full border-2 h-36 w-36'></div>
                        </div>
                        <div>
                        <div className='border-[#09C97F]  rounded-l-full rounded-b-full border-2 h-36 w-36'></div>
                        </div>
                    </div> 

                </div>
                <div className='flex justify-center items-center'>
                <div className="wrapperui">
                  <svg className='animeText'>
                    <text x="50%" y="50%" dy=".35em"  textAnchor="middle">
                      UI / UX
                    </text>
                  </svg>
               </div>
                </div>
                <div className='hidden lg:block'>
                  <div >
                      
                         <div className='hidden md:block'>
                            <div className=' h-60 w-60 border-[#470137] rounded-3xl border-2 absolute  right-5'></div>
                            <div className='absolute h-72 w-72 rounded-3xl bg-[#470137] right-14 top-36 flex items-center justify-center'>
                                <h1 className='font-bold text-[#F75EEE] text-9xl text-center  '>
                                    XD
                                </h1>
                            </div>
                        </div>
                        
                    </div> 
                </div>

                
            
            
            
            
        
        

        
        
        
    
    </div>
    </div>
  )
}

export default UiUxHeader
