import React from 'react'
import cardimg1 from '../blockchain/Images/Blockchain Development.svg'
import cardimg2 from '../blockchain/Images/Smart Contracts.svg'
import cardimg3 from '../blockchain/Images/Tokenization.svg'
import cardimg4 from '../blockchain/Images/Blockchain Integration.svg'
import cardimg5 from '../blockchain/Images/Blockchain Consulting.svg'

const ServicesCards = () => {
  return (
    <div className=' rp mb-24'>  
    
    <div className=' flex justify-center items-center  '>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  mb-16 mt-16 gap-20'>
    {/* card-1  */}
    <div className=' '>
    <div className="relative flex w-[320px] h-[400px] max-[290px]:w-[220px] max-[290px]:h-[420px] flex-col rounded-xl bg-[#a6a6a6] bg-clip-border text-gray-700 shadow-md">
    <div className="relative mx-4 -mt-6 h-40 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40 bg-gradient-to-r from-blue-500 to-blue-600">
    <img src={cardimg1} alt="" className=" w-[80%] -mt-11 ml-7 max-[290px]:-mt-9 " />
    </div>
    <div className="p-6">
      <h5 className=" uppercase mb-2 block text-xl font-semibold leading-snug tracking-normal text-Whiteuse antialiased">
      Blockchain Development
      </h5>
      <p className="block  text-base  text-inherit antialiased  ">
      We build custom blockchain solutions tailored to your unique needs. Whether it is a private blockchain for your business or a decentralized application (DApp), we have the expertise to deliver. 
      </p>
    </div>
    
  </div>
    </div>

    {/* card-2  */}
    <div>
    <div className="relative flex w-[320px] h-[400px] max-[290px]:w-[220px] max-[290px]:h-[420px] flex-col rounded-xl bg-[#a6a6a6] bg-clip-border text-gray-700 shadow-md">
    <div className="relative mx-4 -mt-6 h-40 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40 bg-gradient-to-r from-blue-500 to-blue-600">
    <img src={cardimg2} alt="" className=" w-[60%] ml-14 max-[290px]:ml-10  " />
    </div>
    <div className="p-6">
      <h5 className=" uppercase mb-2 block text-xl font-semibold leading-snug tracking-normal text-Whiteuse antialiased">
      Smart Contracts
      </h5>
      <p className="block  text-base  text-inherit antialiased">
      Automate complex business processes with smart contracts on the blockchain. Our smart contract development streamlines operations, reduces costs, and minimizes the risk of human error. 
      </p>
    </div>
    
  </div>
    </div>

    {/* card-3  */}
    <div>
    <div className="relative flex w-[320px] h-[400px] max-[290px]:w-[220px] max-[290px]:h-[420px] flex-col rounded-xl bg-[#a6a6a6] bg-clip-border text-gray-700 shadow-md">
    <div className="relative mx-4 -mt-6 h-40 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40 bg-gradient-to-r from-blue-500 to-blue-600">
    <img src={cardimg3} alt="" className=" w-[60%] ml-14 max-[290px]:ml-10 max-[290px]:mt-8 " />
    </div>
    <div className="p-6">
      <h5 className=" uppercase mb-2 block  text-xl font-semibold leading-snug tracking-normal text-Whiteuse antialiased">
      Tokenization
      </h5>
      <p className="block text-base text-inherit antialiased">
      Explore the world of digital assets with tokenization. We can help you create and manage digital tokens for various purposes, from fundraising to asset management. 
      </p>
    </div>
    
  </div>
    </div>

    {/* card-4  */}
    <div>
    <div className="relative flex w-[320px] h-[400px] max-[290px]:w-[220px] max-[290px]:h-[420px] flex-col rounded-xl bg-[#a6a6a6] bg-clip-border text-gray-700 shadow-md">
    <div className="relative mx-4 -mt-6 h-40 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40 bg-gradient-to-r from-blue-500 to-blue-600">
    <img src={cardimg4} alt="" className=" w-[80%] ml-11 max-[290px]:ml-8" />
    </div>
    <div className="p-6">
      <h5 className=" uppercase mb-2 block  text-xl font-semibold leading-snug tracking-normal text-Whiteuse antialiased">
      Blockchain Integration
      </h5>
      <p className="block text-base text-inherit antialiased">
      We seamlessly integrate blockchain into your existing systems, making it easy for you to leverage this transformative technology without disrupting your workflow.
      </p>
    </div>
    
  </div>
    </div>

    {/* card-5  */}
    <div>
    <div className="relative flex w-[320px] h-[400px] max-[290px]:w-[220px] max-[290px]:h-[420px] flex-col rounded-xl bg-[#a6a6a6] bg-clip-border text-gray-700 shadow-md">
    <div className="relative mx-4 -mt-6 h-40 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40 bg-gradient-to-r from-blue-500 to-blue-600">
    <img src={cardimg5} alt="" className=" w-[60%] -mt-1 ml-14 max-[290px]:ml-10 " />
    </div>
    <div className="p-6">
      <h5 className=" uppercase mb-2 block  text-xl font-semibold leading-snug tracking-normal text-Whiteuse antialiased">
      Blockchain Consulting
      </h5>
      <p className="block text-base text-inherit antialiased">
      Not sure how blockchain can benefit your business? Our experts provide consulting services to help you identify opportunities and develop a blockchain strategy that works for you.
      </p>
    </div>
    
  </div>
    </div>
      </div>
    </div>
    


    </div>
  )
}

export default ServicesCards
