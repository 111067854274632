import React from 'react';
import ankita from '../../assets/team/ankita.jpg';
import vishal from '../../assets/team/vishal.png';
import vibha from '../../assets/team/vibha.jpg';
import aman from '../../assets/team/aman.jpg';
import priyanshu from '../../assets/team/priyanshu.jpeg';
import yugendra from '../../assets/team/yugendra.jpeg';
import himanshu from '../../assets/team/himanshu.jpeg';
import gyanesh from '../../assets/team/gyanesh.jpeg';
import amanPradhan from '../../assets/team/amanPradhan.jpeg';
import vivek from '../../assets/team/vivek.jpg';
import anish from '../../assets/team/anish.jpg';
import raj from '../../assets/team/RajImage-01.jpeg';
import aditi from '../../assets/team/aditi.jpg';
import nidhi from '../../assets/team/nidhi.jpeg';
import dhanashree from '../../assets/team/dhanashree.jpeg';
import nikhil from '../../assets/team/nikhil.jpg';
import renu from '../../assets/team/renu.jpeg';
import harsh from '../../assets/team/harsh.jpeg';
import ashish from '../../assets/team/ashish.jpg';
import pallavi from '../../assets/team/pallavi.jpg';
import rupali from '../../assets/team/rupali.jpeg';
import dk from '../../assets/team/dk.jpeg';
import nancy from '../../assets/team/nancy.jpeg';
import mayurimam from '../../assets/team/mayurimam.jpg';

function TeamsHeader() {
    const director = [
        {
            name: 'Mayuri Bhanushali',
            role: 'CHRO',
            experience: 'Experience of 8 years ',
            imageUrl: mayurimam,

        },
        {
            name: 'Ankita Khandelwal',
            role: 'CHRO',
            experience: 'Experience of 7 years ',
            imageUrl: ankita,

        }
    ]
    const people = [
        {
            name: 'Vibha Mishra',
            role: 'Software Developer',
            experience: '2 years',
            details: "Full stack developer and team leader with expertise in building secure, optimized applications. Expertise in Node.js, Express, React.js, and MongoDB. Proficient in React Native, SQL, Sequelize, and adept at implementing security measures like rate limiters and authentication.",
            imageUrl: vibha,
        },

        {
            name: 'Vishal Sharma',
            role: 'Full Stack Developer',
            experience: '3 years',
            details: "Proficient Full Stack MERN Developer with expertise in writing clean, scalable code for web applications using MongoDB, Express, React, and Node.js.",
            imageUrl: vishal,
        },
        {
            name: 'Aman Thakur',
            role: 'Back-end Developer',
            experience: '1.5 years',
            details: "Experienced Back-end Stack MERN Developer adept at crafting efficient, scalable code for web applications using MongoDB, Express, React, and Node.js. Specializes in Redux, REST API, and JWT authentication with strong problem-solving skills.",
            imageUrl: aman,
        },
        {
            name: 'Priyanshu Kumawat',
            role: 'Senior Front-end Developer',
            experience: '4 years',
            details: "Expert React developer with a knack for crafting dynamic and responsive user interfaces. Proficient in leveraging React's power to create engaging and high-performance frontend solutions.",
            imageUrl: priyanshu,
        },

        {
            name: 'Nikhil Patil',
            role: 'Software Developer',
            experience: '2 years',
            details: "Proficient in crafting dynamic web applications using React, Node, and Express with MongoDB integration, while also excelling in mobile app development using React Native and Firebase. Skilled in leveraging Three.js to create immersive and interactive 3D experiences.",
            imageUrl: nikhil,
        },
        {
            name: 'Harsh Dawani',
            role: 'Back-end Developer',
            experience: '1 years',
            details: "Experienced backend developer adept at architecting scalable systems, with expertise in database management and implementing robust server-side solutions using technologies like Node.js and MongoDB.",
            imageUrl: harsh,
        },
        {
            name: 'Dhanashree Narkhede',
            role: 'Front-end Developer',
            experience: '1.5 years',
            details: "React developer proficient in architecting dynamic and intuitive user interfaces, adept at optimizing React's potential to build immersive and high-impact frontend experiences.",
            imageUrl: dhanashree,
        },
        {
            name: 'Himanshu Wagmare',
            role: 'Junior Front-end Developer',
            experience: '0.5 years',
            details: "Creating dynamic user interfaces with ReactJS while crafting responsive layouts with HTML and CSS for seamless and engaging frontend experiences.",
            imageUrl: himanshu,
        },
        {
            name: 'Pallavi Yadav',
            role: 'Front-end Developer Intern',
            experience: '2 months',
            details: "Creating dynamic user interfaces with ReactJS while crafting responsive layouts with HTML and CSS for seamless and engaging frontend experiences.",
            imageUrl: pallavi,
        },
    ]

    const design = [
        {
            name: 'Vivekanand Nayak',
            role: 'UI/UX Designer',
            experience: '2.5 years',
            details: "Seasoned UI/UX designer with a proven track record in crafting visually captivating interfaces, blending creativity with user-centric design to deliver engaging digital experiences.",
            imageUrl: vivek,
        },
        {
            name: 'Nidhi Selukar',
            role: 'UI/UX Designer ',
            experience: '4 years',
            details: 'Experienced UI/UX developer adept at crafting intuitive and visually compelling designs, combining user-centric approaches with technical expertise for seamless and engaging user experiences.',
            imageUrl: nidhi,
        },
    ]
    const digitalMarketing = [
        {
            name: 'Ashish Jain',
            role: 'Graphic Designer',
            experience: '3 years',
            details: "Expert Digital Marketer adept at crafting strategic campaigns and optimizing online presence for impactful results.",
            imageUrl: ashish,
        },
        {
            name: 'Renu Kushwah',
            role: 'Digital Marketer ',
            experience: '5 years',
            details: 'Skilled digital marketer proficient in multi-channel campaigns, data-driven strategies, and audience targeting, driving impactful results through innovative marketing approaches.',
            imageUrl: renu,
        },
    ]

    const dataAnalytics = [
        {
            name: 'Yugendra Salukhe',
            role: 'Data Scientist',
            experience: '7 years',
            details: 'A proficient data scientist and analyst adeptly leverages tools like Python, SQL, Excel, tableau and machine learning frameworks to extract actionable insights from complex datasets, driving informed decision-making, business success and research.',
            imageUrl: yugendra,
        },
        {
            name: 'Aditi Malviya',
            role: 'Data Analyst',
            experience: '2 years',
            details: 'An expert developer and data analyst, skilled in Python, R, SQL, Excel and machine learning, able to write optimized and efficient codes. Proficient in data visualization with tools like Tableau.',
            imageUrl: aditi,
        },
        {
            name: 'Rupali Sakre',
            role: 'Data Engineer',
            experience: '3 years',
            details: 'Passionate data engineer skilled in Power BI, Power Platform, Azure, SQL, and Python. Proven expertise in delivering impactful solutions for efficient data analysis and management.',
            imageUrl: rupali,
        },
    ]
    const blockchain = [
        {
            name: 'Aman Pradhan',
            role: 'Blockchain Developer',
            experience: '4 years',
            details: 'Experienced blockchain developer adept at designing and implementing decentralized applications (dApps), proficient in smart contract development, and skilled in various blockchain frameworks and protocols.',
            imageUrl: amanPradhan,
        },

    ]
    const appDevelopment = [
        {
            name: 'Gyanesh Singh',
            role: 'Senior App Developer',
            experience: '4 years',
            details: 'Full stack Android developer with 2 years of experience in Node Js Backend developer',
            imageUrl: gyanesh,
        },
        {
            name: 'Raj Bagdawat',
            role: 'App Developer',
            experience: '2 years',
            details: 'I excel as an Android developer, proficient in Java, APIs, databases, and frameworks. Crafting seamless, user-centric apps with innovation',
            imageUrl: raj,
        },
        {
            name: 'Anish Prasad',
            role: 'App Developer',
            experience: '1 years',
            details: 'Highly skilled and motivated software developer with expertise in both Android development and web development. Proficient in designing, developing, and deploying dynamic mobile applications for Android devices, utilizing Java.',
            imageUrl: anish,
        },
    ]
    const cloud = [
        {
            name: 'Nancy Gupta',
            role: 'DevOps Developer',
            experience: '4 years',
            details: 'Experienced DevOps developer proficient in orchestrating seamless CI/CD pipelines and implementing scalable infrastructure as code solutions for efficient software delivery.',
            imageUrl: nancy,
        },
        {
            name: 'Deevanshu Kushwah',
            role: 'DevOps Developer',
            experience: '3 years',
            details: 'Skilled DevOps developer adept at automating deployment processes and optimizing infrastructure to enhance the reliability and scalability of software systems.',
            imageUrl: dk,
        },
    ]

    const hexagonStyle = {
        width: '100px', // Adjust size as needed
        height: '115px', // Adjust height to match the hexagon's shape
        backgroundColor: '#6b46c1', // Adjust color as needed
        clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };


    return (
        <div className="">
            <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-1">

                <div className=' pt-32'>
                    <h1 className=' text-3xl text-center font-semibold text-Summer py-5'>Leadership Team</h1>
                    <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-4">
                        {director.map((person) => (
                            <li key={person.name}>
                                <div className="flex flex-col items-center   px-1 py-2 ">
                                    <div >
                                        <img className=" h-52 w-40 mt-2 object-cover" style={hexagonStyle} src={person.imageUrl} alt="" />
                                    </div>

                                    <div>
                                        <h3 className="text-base font-semibold  tracking-tight text-center">{person.name}</h3>
                                        <p className="text-sm text-center">{person.role}</p>

                                        <p className="text-xs text-center">{person.experience}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>

                </div>
                <div className=' pt-32'>
                    <h1 className=' text-3xl text-center font-semibold text-Summer py-5'>Software Development Team</h1>

                    <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-3 sm:gap-y-16 xl:col-span-4">
                        {people.map((person) => (
                            <li key={person.name}>
                                <div className="flex flex-col items-center  px-1 py-2 ">
                                    <div >
                                        <img className=" h-52 w-40 mt-2 object-cover" style={hexagonStyle} src={person.imageUrl} alt="" />
                                    </div>

                                    <div>
                                        <h3 className="text-base font-semibold  tracking-tight text-center">{person.name}</h3>
                                        <p className="text-sm text-center">{person.role}, {person.experience}</p>

                                        <p className="text-xs text-center">{person.details}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className=' pt-32'>
                    <h1 className=' text-3xl font-semibold py-5 text-Summer text-center'>App Development Team</h1>

                    <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-3 sm:gap-y-16 xl:col-span-2">
                        {appDevelopment.map((person) => (
                            <li key={person.name}>
                                <div className="flex flex-col items-center gap-x-6  px-1 py-2">
                                    <img className=" h-52 w-40 mt-2 object-cover" style={hexagonStyle} src={person.imageUrl} alt="" />
                                    <div>
                                        <h3 className="text-base font-semibold  tracking-tight text-center">{person.name}</h3>
                                        <p className="text-sm text-center">{person.role}, {person.experience}</p>

                                        <p className="text-xs text-center">{person.details}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className=' pt-32'>
                    <h1 className=' text-3xl font-semibold text-center text-Summer py-5'>Designing Team</h1>

                    <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                        {design.map((person) => (
                            <li key={person.name}>
                                <div className="flex flex-col items-center gap-x-6  px-1 py-2">
                                    <img className="h-52 w-40 mt-2 object-cover" style={hexagonStyle} src={person.imageUrl} alt="" />
                                    <div>
                                        <h3 className="text-base font-semibold  tracking-tight text-center">{person.name}</h3>
                                        <p className="text-sm text-center">{person.role}, {person.experience}</p>

                                        <p className="text-xs text-center md:px-32">{person.details}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className=' pt-32'>
                    <h1 className=' text-3xl font-semibold text-center text-Summer py-5'>Digital Marketing Team</h1>

                    <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                        {digitalMarketing.map((person) => (
                            <li key={person.name}>
                                <div className="flex flex-col items-center gap-x-6  px-1 py-2">
                                    <img className="h-52 w-40 mt-2 object-cover" style={hexagonStyle} src={person.imageUrl} alt="" />
                                    <div>
                                        <h3 className="text-base font-semibold  tracking-tight text-center">{person.name}</h3>
                                        <p className="text-sm text-center">{person.role}, {person.experience}</p>

                                        <p className="text-xs text-center md:px-32">{person.details}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className=' pt-32'>
                    <h1 className=' text-3xl font-semibold text-center text-Summer py-5'>Data Analytics Team</h1>

                    <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-3 sm:gap-y-16 xl:col-span-2">
                        {dataAnalytics.map((person) => (
                            <li key={person.name}>
                                <div className="flex flex-col items-center gap-x-6  px-1 py-2">
                                    <img className="h-52 w-40 mt-2 object-cover" style={hexagonStyle} src={person.imageUrl} alt="" />
                                    <div>
                                        <h3 className="text-base font-semibold  tracking-tight text-center">{person.name}</h3>
                                        <p className="text-sm text-center">{person.role}, {person.experience}</p>

                                        <p className="text-xs text-center ">{person.details}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className=' pt-32'>
                    <h1 className=' text-3xl font-semibold text-center text-Summer py-5'>Blockchain Devlopment</h1>

                    <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-1 sm:gap-y-16 xl:col-span-2">
                        {blockchain.map((person) => (
                            <li key={person.name}>
                                <div className="flex flex-col items-center gap-x-6  px-1 py-2">
                                    <img className="h-52 w-40 mt-2 object-cover" style={hexagonStyle} src={person.imageUrl} alt="" />
                                    <div>
                                        <h3 className="text-base font-semibold  tracking-tight text-center">{person.name}</h3>
                                        <p className="text-sm text-center">{person.role}, {person.experience}</p>

                                        <p className="text-xs text-center md:px-72">{person.details}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className=' pt-32'>
                    <h1 className=' text-3xl font-semibold text-center text-Summer py-5'>Cloud & DevOps Team</h1>

                    <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                        {cloud.map((person) => (
                            <li key={person.name}>
                                <div className="flex flex-col items-center gap-x-6  px-1 py-2">
                                    <img className="h-52 w-40 mt-2 object-cover" style={hexagonStyle} src={person.imageUrl} alt="" />
                                    <div>
                                        <h3 className="text-base font-semibold  tracking-tight text-center">{person.name}</h3>
                                        <p className="text-sm text-center">{person.role}, {person.experience}</p>

                                        <p className="text-xs text-center md:px-32">{person.details}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className=' mb-20'>

                </div>

            </div>
        </div>
    )
}

export default TeamsHeader