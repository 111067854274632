import React from 'react'
import { Helmet } from 'react-helmet'
import TeamsHeader from '../teams/TeamsHeader'
import TeamsImage from '../teams/TeamsImage'


function Teams() {
    return (
        <div>
            <Helmet>
                <title>
                    Teams
                </title>
            </Helmet>
            <TeamsImage />
            <TeamsHeader />
        </div>
    )
}

export default Teams
