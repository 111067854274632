import React from 'react'
import Appdevheader from '../components/appdev/AppdevHeader'
import AppdevContent from '../components/appdev/AppdevContent'
import AppdevPara from '../components/appdev/AppdevPara'
import AppDevContentSecond from '../components/appdev/AppDevContentSecond'
import { Helmet } from 'react-helmet'
// import AppLogo from '../components/appdev/AppLogo'
function AppDev() {
  return (
    <div>
       <Helmet>
      <title>
        App Development - Perceptions Technologies
      </title>
    </Helmet>
      <Appdevheader/>
     
      <AppdevContent/>
      <AppDevContentSecond/>
      {/* <AppLogo /> */}
      <AppdevPara/>
      
    </div>
  )
}

export default AppDev
