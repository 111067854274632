import React from 'react'
import Lottie from 'lottie-react'
import animationData from '../../../../assets/services/digital.json'

function Digital() {
  return (
    <div className='rp font-Poppins break-words sm:mb-24 mb-12'>



      <div className='sm:flex justify-between items-center md:pt-0 sm:pt-5 pt-10'>

        <div className='basis-1/2 flex flex-col gap-y-10'>

        <h2 className='lg:text-6xl md:text-3xl sm:text-left text-center text-2xl'>Your <span className='from-LightSky via-blue-400 to-Summer bg-gradient-to-r bg-clip-text text-transparent'>Companion</span> For Marketing Success</h2>
        <p className='lg:text-lg md:text-base'>At <span className='text-Summer font-bold'>Perceptions Technologies</span>, we understand the pivotal role digital marketing plays in propelling businesses to new heights. Our Digital Marketing services are tailored to help you establish a strong online presence, reach your target audience effectively, and drive tangible results.</p>
      
        </div>

      <Lottie className='sm:basis-[45%]' animationData={animationData} />

      </div>

    </div>
  )
}

export default Digital
